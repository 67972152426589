// This component is the content of the asset library that the user can use to add assets to a scenario.

import * as React from 'react';
import PropTypes from 'prop-types';
import { 
  Box,
  Typography, 
  Grid,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import simulationService from '../../../../../services/simulationService';
import AssetCard from '../../../common-components/existing-assets/AssetCard';
import ASSET_LIBRARY from '../../../common-components/existing-assets/AssetService';
import CreateNewAssetCard from '../../../common-components/create-asset/CreateNewAssetCard';
import CreateAssetDialog from '../../../common-components/create-asset/CreateNewAssetDialog';
import { ASSET_CLASSES } from '../../../common-components/existing-assets/AssetCategories';
import WidgetMessagesDisplayer from '../../../../../components/WidgetMessagesDisplayer';

export default function AssetLibraryContent({ category = null }) {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = React.useState(false);
  const [assets, setAssets] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);
  const [assetToDelete, setAssetToDelete] = React.useState(null);
  const [error, setError] = React.useState({ show: false, message: '' });

  const loadAssets = async () => {
    if (!category) return;
    
    // For locked categories, immediately set loading to false and return
    if (category.isLocked) {
      setLoading(false);
      setAssets([]); // Clear any existing assets
      return;
    }
    
    try {
      setLoading(true);
      // Initialize with the selected category
      await ASSET_LIBRARY.setSelectedCategory(category.id);
      const libraryAssets = await ASSET_LIBRARY.getAssets();
      setAssets(libraryAssets[category.id] || []);
    } catch (error) {
      console.error('Error loading assets:', error);
      // TODO: Add error handling UI
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    loadAssets();
  }, [category]);

  const handleCreateNewAsset = () => {
    setIsCreateDialogOpen(true);
  };

  const handleNewAssetCreated = async () => {
    try {
      setLoading(true);
      // Force a fresh initialization of the asset library for this category
      await ASSET_LIBRARY.setSelectedCategory(category.id);
      await ASSET_LIBRARY.initialize();
      // Get fresh data after initialization
      const libraryAssets = await ASSET_LIBRARY.getAssets();
      setAssets(libraryAssets[category.id] || []);
      setIsCreateDialogOpen(false);
    } catch (error) {
      console.error('Error refreshing assets:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = async (asset) => {
    setAssetToDelete(asset);
    setDeleteConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (assetToDelete) {
      try {
        const response = await simulationService.deleteModelInstance(assetToDelete.id);
        
        if (!response.error) {
          // Update local state after successful API call
          const updatedAssets = assets.filter(asset => asset.id !== assetToDelete.id);
          setAssets(updatedAssets);
          if (category?.id) {
            ASSET_LIBRARY[category.id] = updatedAssets;
          }
        } else {
          // Handle error case
          setError({ show: true, message: response.error });
        }
      } catch (error) {
        // Handle network or other errors
        setError({ 
          show: true, 
          message: error.response?.data?.error || 'Failed to delete asset. Please try again.'
        });
      }
    }
    setDeleteConfirmOpen(false);
    setAssetToDelete(null);
  };

  const handleCancelDelete = () => {
    setDeleteConfirmOpen(false);
    setAssetToDelete(null);
  };

  if (!category) {
    return (
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        height: '100%',
        p: 4
      }}>
        <Typography color="text.secondary">
          Select a category to view available assets
        </Typography>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        height: '100%' 
      }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        {category.name}
      </Typography>
      <Typography color="text.secondary" paragraph>
        {category.description}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <CreateNewAssetCard
            onClick={handleCreateNewAsset}
            title={`Create New ${category.name}`}
            subtitle={`Add a custom ${category.name.toLowerCase()} to the library`}
            isLocked={category.isLocked}
            comingSoonMessage={category.comingSoonMessage}
          />
        </Grid>

        {!category.isLocked && assets.map((asset) => (
          <Grid item xs={12} sm={6} md={4} key={asset.id}>
            <AssetCard
              asset={asset}
              onDelete={handleDeleteClick}
              actions={true}
            />
          </Grid>
        ))}
      </Grid>

      {!category.isLocked && (
        <>
          <CreateAssetDialog
            open={isCreateDialogOpen}
            onClose={() => setIsCreateDialogOpen(false)}
            category={category}
            onSubmit={handleNewAssetCreated}
          />

          <Dialog
            open={deleteConfirmOpen}
            onClose={handleCancelDelete}
            maxWidth="xs"
            fullWidth
          >
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to delete {assetToDelete?.name}?
              </Typography>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
              <Button 
                onClick={handleCancelDelete}
                variant="outlined"
                sx={{
                  color: 'text.primary',
                  borderColor: 'divider',
                  '&:hover': {
                    borderColor: 'primary.main',
                    backgroundColor: 'action.hover'
                  }
                }}
              >
                Cancel
              </Button>
              <Button 
                onClick={handleConfirmDelete}
                variant="contained"
                color="error"
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}

      <WidgetMessagesDisplayer
        open={error.show}
        message={error.message}
        severity="error"
        onClose={() => setError({ show: false, message: '' })}
      />
    </Box>
  );
}

AssetLibraryContent.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.oneOf(Object.values(ASSET_CLASSES)),
    name: PropTypes.string,
    icon: PropTypes.node,
    description: PropTypes.string
  })
};