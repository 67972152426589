import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Grid, 
  Paper, 
  TextField, 
  Typography, 
  Button,
  Card,
  CardContent,
  Stack,
  IconButton,
  Box,
  CircularProgress,
  Collapse,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

// Import components
import WidgetMessagesDisplayer from '../../../../../components/WidgetMessagesDisplayer';
import AssetDropZone from '../components/AssetDropZone';
import DraggableAssetIcon from '../components/DraggableAssetIcon';
import { ASSET_CATEGORIES } from '../../../common-components/existing-assets/AssetCategories';
import CreateAssetDialog from '../../../common-components/create-asset/CreateNewAssetDialog';
import ASSET_LIBRARY from '../../../common-components/existing-assets/AssetService';
import WidgetNewScenarioTopology from '../../../common-components/scenario-topology/WidgetNewScenarioTopology';
import AssetLibrary from '../../asset-library/AssetLibrary';

// Import services
import simulationService from '../../../../../services/simulationService';

const NewSimulationGrid = () => {
  // State declarations and hooks
  const navigate = useNavigate();
  const [simulationId, setSimulationId] = React.useState(null);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [isCreating, setIsCreating] = React.useState(false);
  const hasInitializedRef = React.useRef(false);
  const [messageOpen, setMessageOpen] = React.useState(false);
  const [messageSeverity, setMessageSeverity] = React.useState('info');
  const [statusMessage, setStatusMessage] = React.useState('');
  
  // Additional state
  const [selectedModels, setSelectedModels] = React.useState({});
  const [expandedAssets, setExpandedAssets] = React.useState({});
  const [openScenarioId, setOpenScenarioId] = React.useState(null);
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
  const [creatingAssetType, setCreatingAssetType] = React.useState(null);
  const [isAssetLibraryOpen, setIsAssetLibraryOpen] = React.useState(false);
  
  // Simulation data state
  const [simulationData, setSimulationData] = React.useState({
    name: 'New Simulation',
    start_date: dayjs.utc('2022-01-01 00:00:00').toDate(),
    end_time: dayjs.utc('2022-12-31 23:30:00').toDate(),
    scenarios: []
  });

  const [modelInstances, setModelInstances] = React.useState({});
  const [loadingInstances, setLoadingInstances] = React.useState({});

  // Add these state variables after the other state declarations
  const [hasRun, setHasRun] = React.useState(false);

  // Filter out locked categories at the top of the component
  const availableCategories = ASSET_CATEGORIES.filter(category => !category.isLocked);

  // Create simulation on component mount
  React.useEffect(() => {
    const createInitialSimulation = async () => {
      if (hasInitializedRef.current || simulationId) {
        return;
      }

      setIsCreating(true);
      try {
        hasInitializedRef.current = true;
        
        const simulationResponse = await simulationService.createSimulation({
          name: simulationData.name,
          start_date: simulationData.start_date.toISOString(),
          end_time: simulationData.end_time.toISOString()
        });

        if (!simulationResponse.success) {
          throw new Error(simulationResponse.error);
        }

        setSimulationId(simulationResponse.data.simulation.id);
        showMessage('Simulation created successfully! You can now configure it.', 'success');
      } catch (error) {
        console.error(error);
        showMessage(`Failed to create simulation: ${error.message}`, 'error');
        hasInitializedRef.current = false;
        navigate('/simulator');
      } finally {
        setIsCreating(false);
      }
    };

    createInitialSimulation();
  }, []);

  // Add this cleanup effect after the other useEffect hooks
  React.useEffect(() => {
    return () => {
      // Only attempt cleanup if we have a simulation ID and it hasn't been run
      if (simulationId && !hasRun) {
        simulationService.deleteSimulation(simulationId).catch(error => {
          console.error('Failed to cleanup unused simulation:', error);
        });
      }
    };
  }, [simulationId, hasRun]);

  // Utility functions
  const showMessage = (message, severity = 'info') => {
    // First close any existing message
    setMessageOpen(false);
    
    // Use setTimeout to ensure the previous message is fully closed
    setTimeout(() => {
      setStatusMessage(message);
      setMessageSeverity(severity);
      setMessageOpen(true);
    }, 100);
  };

  // Handlers for simulation updates
  const debouncedUpdateField = React.useCallback(
    debounce(async (field, value) => {
      if (!simulationId) return;

      setIsUpdating(true);
      try {
        const updateData = { [field]: value };
        const response = await simulationService.updateSimulation(simulationId, updateData);
        
        if (!response.success) {
          throw new Error(response.error);
        }
        
        showMessage('Updated successfully', 'success');
      } catch (error) {
        console.error(error);
        showMessage(`Failed to update: ${error.message}`, 'error');
      } finally {
        setIsUpdating(false);
      }
    }, 3500),
    [simulationId]
  );

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setSimulationData(prev => ({ ...prev, name: newName }));
    debouncedUpdateField('name', newName);
  };

  // Scenario handlers
  const handleAddScenario = async () => {
    if (!simulationId) {
      showMessage('Cannot create scenario: No simulation ID available', 'error');
      return;
    }

    try {
      let modelAssociationSuccessful = true;
      
      // If there's an open scenario, try to associate its models first
      if (openScenarioId) {
        const currentScenario = simulationData.scenarios.find(s => s.id === openScenarioId);
        if (currentScenario) {
          const modelInstanceIds = currentScenario.configuration.assets
            .map(asset => selectedModels[asset.id]?.id)
            .filter(Boolean);
          
          if (modelInstanceIds.length > 0) {
            const associateResponse = await simulationService.associateModels(
              openScenarioId, 
              modelInstanceIds
            );
            
            if (!associateResponse.success) {
              modelAssociationSuccessful = false;
              throw new Error(associateResponse.error);
            }
          }
        }
      }

      const scenarioResponse = await simulationService.createScenario({
        name: `Scenario ${simulationData.scenarios.length + 1}`,
        simulation_id: simulationId
      });

      if (!scenarioResponse.success) {
        throw new Error(scenarioResponse.error);
      }

      const newScenario = scenarioResponse.data.scenario;

      setSimulationData(prev => ({
        ...prev,
        scenarios: [...prev.scenarios, {
          id: newScenario.id,
          name: newScenario.name,
          configuration: {
            assets: []
          }
        }]
      }));

      // Open the new scenario if:
      // 1. It's the first scenario (no openScenarioId), OR
      // 2. Model association was successful for the previous scenario
      if (!openScenarioId || modelAssociationSuccessful) {
        setOpenScenarioId(newScenario.id);
        showMessage('First scenario created successfully!', 'success');
      } else {
        showMessage('New scenario created, but model association failed for previous scenario', 'warning');
      }
    } catch (error) {
      console.error('Failed to create scenario:', error);
      showMessage(`Error: ${error.message}`, 'error');
    }
  };

  const handleDeleteScenario = async (scenarioId) => {
    try {
      const response = await simulationService.deleteScenario(scenarioId);
      
      if (response.success) {
        setSimulationData(prev => ({
          ...prev,
          scenarios: prev.scenarios.filter(scenario => scenario.id !== scenarioId)
        }));
        
        if (openScenarioId === scenarioId) {
          setOpenScenarioId(null);
        }
        
        showMessage('Scenario deleted successfully', 'success');
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      console.error('Failed to delete scenario:', error);
      showMessage(`Error deleting scenario: ${error.message}`, 'error');
    }
  };

  const handleScenarioExpand = async (scenarioId) => {
    // If we're closing the scenario (i.e., it's currently open)
    if (openScenarioId === scenarioId) {
      try {
        const currentScenario = simulationData.scenarios.find(s => s.id === scenarioId);
        if (currentScenario) {
          // Get all selected model IDs from the current scenario's assets
          const modelInstanceIds = currentScenario.configuration.assets
            .map(asset => selectedModels[asset.id]?.id)
            .filter(Boolean);
          
          if (modelInstanceIds.length > 0) {
            const associateResponse = await simulationService.associateModels(
              scenarioId, 
              modelInstanceIds
            );
            
            if (!associateResponse.success) {
              showMessage(`Error: ${associateResponse.error}`, 'error');
              return; // Don't close the scenario if association fails
            }
            
            showMessage('Models associated successfully!', 'success');
          }
        }
      } catch (error) {
        console.error('Failed to associate models:', error);
        showMessage(`Error: ${error.message}`, 'error');
        return; // Don't close the scenario if there's an error
      }
    }
    
    // Toggle scenario expansion state
    setOpenScenarioId(openScenarioId === scenarioId ? null : scenarioId);
  };

  // Asset handlers
  const handleAssetModelSelect = async (assetId, modelData) => {
    setSelectedModels(prev => ({
      ...prev,
      [assetId]: modelData
    }));

    setSimulationData(prev => ({
      ...prev,
      scenarios: prev.scenarios.map(scenario => {
        if (scenario.id === openScenarioId) {
          return {
            ...scenario,
            configuration: {
              ...scenario.configuration,
              assets: scenario.configuration.assets.map(asset =>
                asset.id === assetId
                  ? { ...asset, modelData, params: modelData.params }
                  : asset
              )
            }
          };
        }
        return scenario;
      })
    }));
  };

  const fetchModelInstances = async (assetType) => {
    const category = ASSET_CATEGORIES.find(cat => cat.id === assetType.toLowerCase());
    if (!category) return;

    try {
      setLoadingInstances(prev => ({ ...prev, [assetType]: true }));
      
      // Use ASSET_LIBRARY directly
      await ASSET_LIBRARY.setSelectedCategory(category.id);
      const libraryAssets = await ASSET_LIBRARY.getAssets();
      
      setModelInstances(prev => ({ 
        ...prev, 
        [assetType]: libraryAssets[category.id] || []
      }));
    } catch (error) {
      console.error(`Error fetching ${category.id} instances:`, error);
      showMessage(`Failed to load ${category.name} instances`, 'error');
    } finally {
      setLoadingInstances(prev => ({ ...prev, [assetType]: false }));
    }
  };

  const handleAssetDrop = async (type) => {
    const newAssetId = Date.now();
    const category = ASSET_CATEGORIES.find(cat => cat.id === type);
    
    // Fetch model instances if not already loaded
    if (!modelInstances[type]) {
      await fetchModelInstances(type);
    }
    
    setSimulationData(prev => ({
      ...prev,
      scenarios: prev.scenarios.map(s => 
        s.id === openScenarioId 
          ? {
              ...s,
              configuration: {
                ...s.configuration,
                // Filter out any existing asset of the same type and add the new one
                assets: [
                  ...s.configuration.assets.filter(asset => asset.type !== type),
                  {
                    id: newAssetId,
                    type,
                    name: category?.name || type,
                    params: {}
                  }
                ]
              }
            }
          : s
      )
    }));
    
    // Clean up any expanded state for the removed asset
    setExpandedAssets(prev => {
      const newState = { ...prev };
      // Remove expanded state for old assets of this type
      Object.entries(prev).forEach(([key]) => {
        const asset = simulationData.scenarios
          .find(s => s.id === openScenarioId)
          ?.configuration.assets
          .find(a => a.id.toString() === key && a.type === type);
        if (asset) {
          delete newState[key];
        }
      });
      // Add expanded state for new asset
      newState[newAssetId] = true;
      return newState;
    });

    // Clean up selected models for the removed asset
    setSelectedModels(prev => {
      const newState = { ...prev };
      // Remove selected models for old assets of this type
      Object.entries(prev).forEach(([key]) => {
        const asset = simulationData.scenarios
          .find(s => s.id === openScenarioId)
          ?.configuration.assets
          .find(a => a.id.toString() === key && a.type === type);
        if (asset) {
          delete newState[key];
        }
      });
      return newState;
    });
  };

  const handleDeleteAsset = (assetId) => {
    setSimulationData(prev => ({
      ...prev,
      scenarios: prev.scenarios.map(s => 
        s.id === openScenarioId 
          ? {
              ...s,
              configuration: {
                ...s.configuration,
                assets: s.configuration.assets.filter(a => a.id !== assetId)
              }
            }
          : s
      )
    }));
    
    setExpandedAssets(prev => {
      const newState = { ...prev };
      delete newState[assetId];
      return newState;
    });
  };

  const handleAssetExpand = (assetId) => {
    setExpandedAssets(prev => ({
      ...prev,
      [assetId]: !prev[assetId]
    }));
  };

  const handleNewAssetCreated = async (assetType) => {
    try {
      // Refresh the model instances for this asset type
      await fetchModelInstances(assetType);
    } catch (error) {
      console.error('Failed to refresh model instances:', error);
      // You might want to show an error message to the user
    }
  };

  // Run simulation
  const handleRunSimulation = async () => {
    if (!simulationId) {
      showMessage('Cannot run simulation: No simulation ID available', 'error');
      return;
    }

    // Check if there are any scenarios
    if (simulationData.scenarios.length === 0) {
      showMessage('Cannot run simulation: At least one scenario must be created', 'error');
      return;
    }

    // Check if at least one scenario has configured assets with selected models
    const hasConfiguredScenario = simulationData.scenarios.some(scenario => {
      const scenarioAssets = scenario.configuration.assets;
      return scenarioAssets.some(asset => selectedModels[asset.id]?.id);
    });

    if (!hasConfiguredScenario) {
      showMessage('Cannot run simulation: At least one scenario must have configured assets with selected models', 'error');
      return;
    }

    try {
      // First, handle the currently open scenario if any
      if (openScenarioId) {
        const currentScenario = simulationData.scenarios.find(s => s.id === openScenarioId);
        if (currentScenario) {
          const modelInstanceIds = currentScenario.configuration.assets
            .map(asset => selectedModels[asset.id]?.id)
            .filter(Boolean);
          
          if (modelInstanceIds.length > 0) {
            const associateResponse = await simulationService.associateModels(
              openScenarioId, 
              modelInstanceIds
            );
            
            if (!associateResponse.success) {
              throw new Error(associateResponse.error);
            }
          }
        }
      }

      // Then, ensure all other scenarios have their models associated
      for (const scenario of simulationData.scenarios) {
        if (scenario.id === openScenarioId) continue; // Skip the one we just handled

        const modelInstanceIds = scenario.configuration.assets
          .map(asset => selectedModels[asset.id]?.id)
          .filter(Boolean);
        
        if (modelInstanceIds.length > 0) {
          const associateResponse = await simulationService.associateModels(
            scenario.id, 
            modelInstanceIds
          );
          
          if (!associateResponse.success) {
            throw new Error(`Failed to associate models for scenario ${scenario.name}: ${associateResponse.error}`);
          }
        }
      }

      showMessage('All models associated successfully!', 'success');

      // Now proceed with running the simulation
      const response = await simulationService.runSimulation(simulationId);
      
      if (!response.success) {
        throw new Error(response.error);
      }

      showMessage('Simulation started successfully!', 'success');
      setHasRun(true); // Set hasRun to true before navigating
      navigate(`/simulator/${simulationId}`);
      
    } catch (error) {
      console.error('Failed to run simulation:', error);
      showMessage(`Error: ${error.message}`, 'error');
    }
  };

  const debouncedUpdateScenario = React.useCallback(
    debounce(async (scenarioId, updateData) => {
      let response;
      try {
        setIsUpdating(true);
        response = await simulationService.updateScenario(scenarioId, updateData);
        if (!response.success) {
          throw new Error(response.error);
        }
        showMessage('Scenario updated successfully!', 'success');
      } catch (error) {
        console.error('Failed to update scenario:', error);
        showMessage(`Failed to update scenario: ${error.message}`, 'error');
        // Revert the name change in case of error
        setSimulationData(prev => ({
          ...prev,
          scenarios: prev.scenarios.map(s => 
            s.id === scenarioId 
              ? { ...s, name: response?.data?.scenario?.name || s.name }
              : s
          )
        }));
      } finally {
        setIsUpdating(false);
      }
    }, 3500),
    []
  );

  const renderDraggableAsset = (category) => {
    const config = {
      name: category.name,
      icon: category.icon
    };

    return (
      <DraggableAssetIcon 
        key={category.id}
        type={category.id}
        config={config}
      />
    );
  };

  return (
    <>
      <WidgetMessagesDisplayer
        open={messageOpen}
        message={statusMessage}
        severity={messageSeverity}
        onClose={() => setMessageOpen(false)}
      />
      <DndProvider backend={HTML5Backend}>
        <Box 
          sx={{ 
            width: '100%', 
            maxWidth: { sm: '100%', md: '1700px' },
            margin: '0 auto',
            pl: { xs: 0.5, sm: 1 }, // Minimal left padding
            pr: { xs: 2, sm: 3 }    // Larger right padding
          }}
        >
          <Box sx={{ width: '100%', mt: 4 }}> {/* Reduced top margin */}
            <Grid container spacing={3}> {/* Reduced spacing */}
              <Grid item xs={12}>
                <Paper elevation={3} sx={{ p: 4 }}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, mb: 0 }}>
                      Simulation Details
                    </Typography>
                    <Button
                      variant="contained"
                      startIcon={<LibraryBooksIcon />}
                      onClick={() => setIsAssetLibraryOpen(true)}
                      sx={{
                        height: '40px',
                        borderRadius: 2,
                        fontSize: '0.9rem',
                        fontWeight: 500,
                        backgroundColor: (theme) => theme.palette.primary.main,
                        '&:hover': {
                          backgroundColor: (theme) => theme.palette.primary.dark,
                        },
                      }}
                    >
                      Asset Library
                    </Button>
                  </Stack>
                  {isCreating ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 3 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <TextField
                      fullWidth
                      label="Simulation Name"
                      value={simulationData.name}
                      onChange={handleNameChange}
                      margin="normal"
                      disabled={isUpdating}
                    />
                  )}
                </Paper>

                {!isCreating && (
                  <>
                    <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
                      <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, mb: 3 }}>
                        Scenarios
                      </Typography>
                      
                      <Stack spacing={3} sx={{ mb: 3 }}>
                        {simulationData.scenarios.map((scenario) => (
                          <Card key={scenario.id} variant="outlined">
                            <CardContent>
                              <Stack spacing={2}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                  <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography variant="subtitle1">
                                      {scenario.name}
                                    </Typography>
                                  </Stack>
                                  <Stack direction="row" spacing={1}>
                                    <IconButton
                                      size="small"
                                      onClick={() => handleScenarioExpand(scenario.id)}
                                      color="primary"
                                    >
                                      <ExpandMoreIcon 
                                        sx={{ 
                                          transform: openScenarioId === scenario.id ? 'rotate(180deg)' : 'none',
                                          transition: 'transform 0.2s'
                                        }}
                                      />
                                    </IconButton>
                                    <IconButton
                                      size="small"
                                      onClick={() => handleDeleteScenario(scenario.id)}
                                      color="error"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Stack>
                                </Stack>

                                <Collapse in={openScenarioId === scenario.id}>
                                  <Stack spacing={2}>
                                    <TextField
                                      fullWidth
                                      label="Scenario Name"
                                      value={scenario.name}
                                      disabled={isUpdating}
                                      onChange={(e) => {
                                        const newName = e.target.value;
                                        setSimulationData(prev => ({
                                          ...prev,
                                          scenarios: prev.scenarios.map(s => 
                                            s.id === scenario.id 
                                              ? { ...s, name: newName }
                                              : s
                                          )
                                        }));
                                        debouncedUpdateScenario(scenario.id, { name: newName });
                                      }}
                                    />

                                    {/* Available Assets Row */}
                                    <Box sx={{ 
                                      width: '100%',
                                      overflowX: 'auto',
                                      py: 2,
                                      borderBottom: 1,
                                      borderColor: 'divider'
                                    }}>
                                      <Box sx={{ p: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                                        {availableCategories.map((category) => renderDraggableAsset(category))}
                                      </Box>
                                    </Box>

                                    {/* Grid for AssetDropZone and Topology */}
                                    <Grid container spacing={1}>
                                      {/* Asset Drop Zone - Left Side */}
                                      <Grid item xs={12} md={7}>
                                        <AssetDropZone 
                                          onDrop={handleAssetDrop}
                                          assets={scenario.configuration.assets}
                                          expandedAssets={expandedAssets}
                                          handleAssetExpand={handleAssetExpand}
                                          handleDeleteAsset={handleDeleteAsset}
                                          handleAssetParamChange={(assetId, paramName, value) => {
                                            setSimulationData(prev => ({
                                              ...prev,
                                              scenarios: prev.scenarios.map(s => 
                                                s.id === openScenarioId 
                                                  ? {
                                                      ...s,
                                                      configuration: {
                                                        ...s.configuration,
                                                        assets: s.configuration.assets.map(a => 
                                                          a.id === assetId 
                                                            ? { ...a, params: { ...a.params, [paramName]: value } }
                                                            : a
                                                        )
                                                      }
                                                    }
                                                  : s
                                              )
                                            }));
                                          }}
                                          onAssetModelSelect={handleAssetModelSelect}
                                          modelInstances={modelInstances}
                                          loadingInstances={loadingInstances}
                                          onAssetCreated={handleNewAssetCreated}
                                        />
                                      </Grid>

                                      {/* Topology Visualization - Right Side */}
                                      <Grid item xs={12} md={5}>
                                        <Paper elevation={3} sx={{ height: '100%', p: 2 }}>
                                          <Typography variant="h6" gutterBottom sx={{ pl: 2 }}>
                                            Scenario Topology
                                          </Typography>
                                          <WidgetNewScenarioTopology 
                                            assets={scenario.configuration.assets.map(asset => ({
                                              id: asset.id,
                                              name: asset.name,
                                              type: asset.type,
                                              icon: ASSET_CATEGORIES.find(cat => cat.id === asset.type)?.icon,
                                              selectedInstance: asset.modelData && asset.modelData.params?.name 
                                                ? { 
                                                    name: asset.modelData.params.name
                                                  }
                                                : undefined
                                            }))}
                                          />
                                        </Paper>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </Collapse>
                              </Stack>
                            </CardContent>
                          </Card>
                        ))}
                      </Stack>

                      <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={handleAddScenario}
                        fullWidth
                        sx={{
                          borderRadius: 3,
                          p: 2,
                          borderStyle: 'dashed',
                          borderWidth: 2,
                          fontSize: '1rem',
                          fontWeight: 500,
                          '&:hover': {
                            borderStyle: 'dashed',
                            backgroundColor: 'action.hover',
                            transform: 'translateY(-2px)',
                            transition: 'all 0.3s ease',
                          },
                        }}
                      >
                        Add Scenario
                      </Button>
                    </Paper>

                    <Stack alignItems="center" sx={{ mt: 5, mb: 4 }}>
                      <Button
                        variant="contained"
                        startIcon={<PlayArrowIcon />}
                        onClick={handleRunSimulation}
                        sx={{ 
                          height: '56px',
                          width: '240px',
                          borderRadius: 3,
                          fontSize: '1.1rem',
                          fontWeight: 500,
                        }}
                      >
                        Run Simulation
                      </Button>
                    </Stack>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </DndProvider>

      <AssetLibrary
        open={isAssetLibraryOpen}
        onClose={() => setIsAssetLibraryOpen(false)}
      />

      <CreateAssetDialog
        open={createDialogOpen}
        onClose={() => {
          setCreateDialogOpen(false);
          setCreatingAssetType(null);
        }}
        onSubmit={handleNewAssetCreated}
        category={creatingAssetType ? {
          id: availableCategories.find(cat => cat.id === creatingAssetType)?.id || '',
          name: availableCategories.find(cat => cat.id === creatingAssetType)?.name || '',
          description: ''
        } : null}
      />
    </>
  );
};

export default NewSimulationGrid;
