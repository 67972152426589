// This widget is used to display the static results for the simulation
// It is used within the AssetResultsCard component

import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import simulationService from '../../../../../../services/simulationService';
import { STATIC_METRICS_CONFIG, formatMetricValue } from '../../../../common-components/metrics/MetricsConfig';
import WidgetSankey from './charts/WidgetSankey';
import WidgetCashflow from './charts/WidgetCashflow';
import WidgetExistingScenarioTopology from '../../../../common-components/scenario-topology/WidgetExistingScenarioTopology';
import WidgetFloatingComments from '../comments/WidgetFloatingComments';
import WidgetUnifiedMetrics from './metrics/WidgetUnifiedMetrics';

const DETAIL_VIEW_OPTIONS = {
  TIMESERIES: 'timeseries',
  DAILY: 'daily',
  MONTHLY: 'monthly'
};

const WidgetStaticResults = ({ 
  simulationId, 
  scenarioId, 
  scenarioName, 
  assets, 
  selectedAssetId, 
  onAssetSelect
}) => {
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [view, setView] = React.useState('economics');
  const [detailView, setDetailView] = React.useState(DETAIL_VIEW_OPTIONS.TIMESERIES);

  React.useEffect(() => {
    const fetchResults = async () => {
      try {
        const response = await simulationService.getScenarioResults(simulationId, scenarioId);
        if (response.success) {
          // Parse the comment string if it exists
          let parsedComment;
          try {
            parsedComment = response.data.comment ? JSON.parse(response.data.comment) : {};
          } catch (e) {
            parsedComment = {
              financial_comment: response.data.comment || '',
              energy_flow_comment: ''
            };
          }

          setData({
            ...response.data,
            comment: parsedComment,
            comments: response.data.scenario?.comment || ''
          });
        } else {
          throw new Error(response.error);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    // Reset comments when scenario changes
    setData(prev => ({
      ...prev,
      comments: '', // Reset comments while loading new scenario
      comment: { financial_comment: '', energy_flow_comment: '' }
    }));
    
    fetchResults();
  }, [simulationId, scenarioId]); // This will trigger when scenarioId changes

  // Helper function to check if a metrics section has data
  const hasMetricsData = (sectionKey, data) => {
    if (!data) return false;
    const metricsSection = data[`${sectionKey}_metrics`];
    return metricsSection && Object.values(metricsSection).some(value => value !== null);
  };

  const renderMetricsSection = (sectionKey, data) => {
    const section = STATIC_METRICS_CONFIG[sectionKey];
    const metricsData = data[`${sectionKey}_metrics`];
    const validMetrics = Object.entries(section.metrics).filter(([key]) => {
      return metricsData[key] !== null && 
             key !== 'total_import_cost' && 
             key !== 'total_export_revenue';
    });
    const gridSize = 12 / validMetrics.length;

    return (
      <Box>
        <Grid container spacing={2}>
          {validMetrics.map(([key, { label, type }]) => (
            <Grid item xs={12} sm={gridSize} key={key}>
              <Box sx={{ p: 1 }}>
                <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                  {label}
                </Typography>
                <Typography variant="body2">
                  {metricsData[key] === null ? 'N/A' : formatMetricValue(metricsData[key], type)}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  const renderFinancialMetrics = (data) => {
    if (!hasMetricsData('financial', data)) return null;

    return renderMetricsSection('financial', data);
  };

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const handleDetailViewChange = (event, newDetailView) => {
    if (newDetailView !== null) {
      setDetailView(newDetailView);
    }
  };

  const handleSaveComment = async (newComment) => {
    try {
      const response = await simulationService.updateScenario(scenarioId, { comment: newComment });
      if (!response.success) {
        throw new Error(response.error);
      }
    } catch (error) {
      console.error('Failed to save comment:', error);
      // You may want to add error handling UI here
    }
  };

  const handleSaveResultsComment = async (newComment, section) => {
    try {
      // Create a single string that contains both comments
      const existingComments = data?.comment || {};
      const otherSection = section === 'financial_comment' ? 'energy_flow_comment' : 'financial_comment';
      const combinedComment = JSON.stringify({
        [section]: newComment,
        [otherSection]: existingComments[otherSection] || ''
      });

      const response = await simulationService.updateScenarioResults(simulationId, scenarioId, {
        comment: combinedComment  // Send as a single string
      });

      if (!response.success) {
        throw new Error(response.error);
      }

      // Update local state
      setData(prev => ({
        ...prev,
        comment: JSON.parse(combinedComment)  // Parse back to object for local state
      }));
    } catch (error) {
      console.error(`Failed to save ${section} comment:`, error);
    }
  };

  const renderDetailContent = () => {
    return (
      <Box sx={{ mt: 2 }}>
        <Stack spacing={2}>
          {/* Detail View Toggle */}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <ToggleButtonGroup
              value={detailView}
              exclusive
              onChange={handleDetailViewChange}
              aria-label="detail view selector"
              size="small"
            >
              <ToggleButton value={DETAIL_VIEW_OPTIONS.TIMESERIES} aria-label="timeseries view">
                Timeseries
              </ToggleButton>
              <ToggleButton value={DETAIL_VIEW_OPTIONS.DAILY} aria-label="daily view">
                Daily Profile
              </ToggleButton>
              <ToggleButton value={DETAIL_VIEW_OPTIONS.MONTHLY} aria-label="monthly view">
                Monthly Distribution
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {/* Pass the correct scenario data structure */}
          <WidgetUnifiedMetrics
            simulationId={simulationId}
            scenarioId={scenarioId}
            scenario={{ model_instances: assets.map(asset => ({
              id: asset.id,
              type: asset.type,
              name: asset.name
            })) }}
            viewType={detailView}
          />
        </Stack>
      </Box>
    );
  };

  const renderContent = () => {
    switch (view) {
      case 'economics':
        return (
          <>
            {data && renderFinancialMetrics(data)}
            {data && hasMetricsData('financial', data) && (
              <>
                <Divider />
                {/* Add Financial Comments Widget */}
                <Box sx={{ position: 'relative', mb: 2 }}>
                  <WidgetFloatingComments 
                    comments={data?.comment?.financial_comment || ''}
                    onSave={(comment) => handleSaveResultsComment(comment, 'financial_comment')}
                    title="Financial Analysis Comments"
                    section="results"
                  />
                </Box>
                <WidgetCashflow 
                  data={data} 
                  simulationId={simulationId}
                  scenarioId={scenarioId}
                />
              </>
            )}
          </>
        );
      case 'energy':
        return (
          <>
            {data && Object.keys(STATIC_METRICS_CONFIG).some(key => 
              key !== 'financial' && hasMetricsData(key, data)
            ) ? (
              <>
                {/* Add Energy Flow Comments Widget */}
                <Box sx={{ position: 'relative', mb: 2 }}>
                  <WidgetFloatingComments 
                    comments={data?.comment?.energy_flow_comment || ''}
                    onSave={(comment) => handleSaveResultsComment(comment, 'energy_flow_comment')}
                    title="Energy Flow Analysis Comments"
                    section="results"
                  />
                </Box>
                <WidgetSankey data={data} />
              </>
            ) : (
              <Typography color="text.secondary" align="center">
                No energy metrics available for this scenario
              </Typography>
            )}
          </>
        );
      case 'detail':
        return renderDetailContent();
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography>Loading results...</Typography>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography color="error">Error: {error}</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack spacing={3}>
          {/* Simulation Name and Comments Section */}
          <Box>
            <Typography variant="h5" gutterBottom>
              {scenarioName}
            </Typography>
            
            {/* Comments Section for Scenario */}
            <Box sx={{ mt: 2, mb: 2, position: 'relative' }}>
              <WidgetFloatingComments 
                comments={data?.comments || ''}
                onSave={handleSaveComment}
                title="Scenario Comments"
                section="scenario"
              />
            </Box>

            {/* Topology Section */}
            <Box sx={{ mt: 3 }}>
              <WidgetExistingScenarioTopology
                assets={assets}
                selectedAssetId={selectedAssetId}
                onAssetSelect={onAssetSelect}
                scenarioId={scenarioId}
                simulationId={simulationId}
              />
            </Box>
          </Box>

          {/* View Toggle */}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <ToggleButtonGroup
              value={view}
              exclusive
              onChange={handleViewChange}
              aria-label="view selector"
              size="small"
            >
              <ToggleButton value="economics" aria-label="economics view">
                Economics
              </ToggleButton>
              <ToggleButton value="energy" aria-label="energy view">
                Energy
              </ToggleButton>
              <ToggleButton value="detail" aria-label="detail view">
                Detail
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {/* Content based on selected view */}
          {renderContent()}
        </Stack>
      </CardContent>
    </Card>
  );
};

WidgetStaticResults.propTypes = {
  simulationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  scenarioId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  scenarioName: PropTypes.string.isRequired,
  assets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    icon: PropTypes.node,
    selectedInstance: PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  })).isRequired,
  selectedAssetId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onAssetSelect: PropTypes.func.isRequired
};

export default WidgetStaticResults;
