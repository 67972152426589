// This file handles the UI presentation and class mapping.
// It defines how different energy assets should be categorized 
// and displayed in the UI.

import SolarPowerIcon from '@mui/icons-material/SolarPower';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import PaidIcon from '@mui/icons-material/Paid';
import EvStationIcon from '@mui/icons-material/EvStation';
import * as React from 'react';

// Define the asset classes that match the backend API
export const ASSET_CLASSES = {
  PV: 'pv',
  STORAGE: 'battery',
  LOAD: 'load',
  CONTROLLER: 'controller',
  TARIFF: 'tariff',
  EV_CHARGER: 'ev_charger'
};

export const ASSET_CATEGORIES = [
  {
    id: ASSET_CLASSES.PV,
    name: 'PV System',
    icon: <SolarPowerIcon />,
    description: 'Solar panel configurations and systems'
  },
  {
    id: ASSET_CLASSES.STORAGE,
    name: 'Energy Storage',
    icon: <BatteryChargingFullIcon />,
    description: 'Battery storage systems'
  },
  {
    id: ASSET_CLASSES.LOAD,
    name: 'Load',
    icon: <HomeIcon />,
    description: 'Energy consumption profiles'
  },
  {
    id: ASSET_CLASSES.CONTROLLER,
    name: 'Controller',
    icon: <SettingsIcon />,
    description: 'Energy management and control systems'
  },
  {
    id: ASSET_CLASSES.TARIFF,
    name: 'Tariff',
    icon: <PaidIcon />,
    description: 'Energy pricing and tariff structures'
  },
  {
    id: ASSET_CLASSES.EV_CHARGER,
    name: 'EV Chargers',
    icon: <EvStationIcon />,
    description: 'Electric Vehicle Charging Stations',
    isLocked: true,
    comingSoonMessage: 'Coming Soon!'
  }
];

export const ASSET_TYPE_LABELS = {
  // PV Systems
  pv: 'PV System',
  pv_weather: 'Weather-based PV',
  pv_profile: 'Profile-based PV',
  
  // Storage
  battery: 'Battery Storage',
  
  // Controllers
  controller_base: 'Base Controller',
  controller_grid: 'Grid Controller',
  controller_pvcurtailment: 'PV Curtailment Controller',
  
  // Loads
  load: 'Load Profile',
  
  // Tariffs
  variable_tariff: 'Variable Tariff',
  fixed_tariff: 'Fixed Tariff',
  dynamic_tariff: 'Dynamic Tariff'
};

// Helper function to get category config by ID
export const getAssetCategoryConfig = (categoryId) => 
  ASSET_CATEGORIES.find(category => category.id === categoryId);

// Helper function to get icon component for a specific asset type
export const getAssetIcon = (assetType) => {
  // Find matching category based on asset type prefix
  const category = ASSET_CATEGORIES.find(cat => {
    if (assetType.startsWith('pv')) return cat.id === ASSET_CLASSES.PV;
    if (assetType === 'battery') return cat.id === ASSET_CLASSES.STORAGE;
    if (assetType === 'load') return cat.id === ASSET_CLASSES.LOAD;
    if (assetType.startsWith('controller')) return cat.id === ASSET_CLASSES.CONTROLLER;
    if (assetType.startsWith('tariff')) return cat.id === ASSET_CLASSES.TARIFF;
    if (assetType === 'ev_charger') return cat.id === ASSET_CLASSES.EV_CHARGER;
    return false;
  });

  // Return the icon component from the category or null if not found
  return category ? category.icon.type : null;
};

export default ASSET_CATEGORIES; 