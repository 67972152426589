import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const WidgetSimulationInlineComments = ({ 
  comments = '', 
  onSave = () => {},
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [editedComments, setEditedComments] = React.useState(comments);
  const [displayedComments, setDisplayedComments] = React.useState(comments);

  // Clean HTML content by removing consecutive empty paragraphs
  const cleanHtmlContent = (html) => {
    if (!html) return html;
    
    // Replace multiple consecutive empty paragraphs with a single one
    return html
      .replace(/(<p><br><\/p>){2,}/g, '<p><br></p>')
      .replace(/(<p>\s*<\/p>){2,}/g, '<p></p>')
      .trim();
  };

  // Modified save handler
  const handleSave = () => {
    const cleanedContent = cleanHtmlContent(editedComments);
    onSave(cleanedContent);
    setDisplayedComments(cleanedContent);
    setIsEditing(false);
  };

  // Quill editor modules configuration
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'align': [] }],
      [{ 'color': [] }, { 'background': [] }],
      ['blockquote', 'code-block'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false // Prevents Quill from creating extra line breaks
    }
  };

  // Quill editor formats
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'align', 'color', 'background',
    'blockquote', 'code-block',
    'list', 'bullet',
    'link'
  ];

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
        <Typography variant="subtitle1" color="text.secondary">
          Simulation Description
        </Typography>
        {!isEditing && (
          <IconButton onClick={() => setIsEditing(true)} size="small" color="primary">
            <EditIcon />
          </IconButton>
        )}
      </Box>

      {isEditing ? (
        <>
          <Box sx={{ 
            mb: 2,
            '& .ql-container': {
              height: '120px',
              backgroundColor: 'background.paper',
            },
            '& .ql-editor': {
              minHeight: '120px',
              maxHeight: '120px',
              overflow: 'auto',
            },
            '& .ql-toolbar': {
              backgroundColor: 'background.paper',
              borderColor: 'divider',
            }
          }}>
            <ReactQuill
              value={editedComments}
              onChange={setEditedComments}
              modules={modules}
              formats={formats}
              placeholder="Add simulation description here..."
              theme="snow"
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <Button
              size="small"
              startIcon={<CloseIcon />}
              onClick={() => {
                setEditedComments(displayedComments);
                setIsEditing(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              startIcon={<SaveIcon />}
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </>
      ) : (
        <Box
          sx={{ 
            '& .ql-editor': {
              p: 0,
              minHeight: 'unset'
            }
          }}
        >
          {displayedComments ? (
            <div 
              dangerouslySetInnerHTML={{ __html: displayedComments }}
              style={{
                color: 'text.secondary',
                minHeight: '1.5em',
              }}
            />
          ) : (
            <Typography
              variant="body2"
              sx={{ 
                fontStyle: 'italic',
                color: 'text.disabled'
              }}
            >
              No description yet. Click edit to add one.
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

WidgetSimulationInlineComments.propTypes = {
  comments: PropTypes.string,
  onSave: PropTypes.func
};

export default WidgetSimulationInlineComments; 