// Side menu that provides navigation options for the user.
// Used in the SideMenu component (desktop and mobile).

import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
//import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
// import ShowChartRoundedIcon from '@mui/icons-material/ShowChartRounded';  // Commented out since we're not using it
import BalanceIcon from '@mui/icons-material/Balance';
// import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
// import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { useNavigate, useLocation } from 'react-router-dom';
import WidgetFeedback from './WidgetFeedback';
import PropTypes from 'prop-types';

const mainListItems = [
  // { text: 'Home', icon: <HomeRoundedIcon />, path: '/home' },
  { text: 'Simulator', icon: <BalanceIcon />, path: '/simulator' },
  // { text: 'Dashboard', icon: <ShowChartRoundedIcon />, path: '/dashboard' },
];

const secondaryListItems = [
  // { text: 'Settings', icon: <SettingsRoundedIcon /> },
  // { text: 'About', icon: <InfoRoundedIcon /> },
];

export default function MenuContent({ collapsed = false }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [feedbackOpen, setFeedbackOpen] = React.useState(false);

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
      <List dense>
        {mainListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <ListItemButton 
              selected={location.pathname === item.path}
              onClick={() => navigate(item.path)}
              sx={{
                minHeight: 48,
                justifyContent: collapsed ? 'center' : 'initial',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: collapsed ? 0 : 3,
                  justifyContent: 'center',
                }}
              >
                {item.icon}
              </ListItemIcon>
              {!collapsed && <ListItemText primary={item.text} />}
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <List dense>
        {secondaryListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: collapsed ? 'center' : 'initial',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: collapsed ? 0 : 3,
                  justifyContent: 'center',
                }}
              >
                {item.icon}
              </ListItemIcon>
              {!collapsed && <ListItemText primary={item.text} />}
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem disablePadding>
          <ListItemButton 
            onClick={() => setFeedbackOpen(true)}
            sx={{
              minHeight: 48,
              justifyContent: collapsed ? 'center' : 'initial',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: collapsed ? 0 : 3,
                justifyContent: 'center',
              }}
            >
              <HelpRoundedIcon />
            </ListItemIcon>
            {!collapsed && <ListItemText primary="Feedback" />}
          </ListItemButton>
        </ListItem>
      </List>

      <WidgetFeedback 
        open={feedbackOpen}
        onClose={() => setFeedbackOpen(false)}
      />
    </Stack>
  );
}

MenuContent.propTypes = {
  collapsed: PropTypes.bool,
};