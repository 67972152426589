import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Box,
  alpha
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import LockIcon from '@mui/icons-material/Lock';
import { useTheme } from '@mui/material/styles';

export default function CreateNewAssetCard({ 
  onClick, 
  title, 
  subtitle,
  isLocked = false,
  comingSoonMessage
}) {
  const theme = useTheme();

  return (
    <Card
      variant="outlined"
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: isLocked ? 'default' : 'pointer',
        transition: 'all 0.2s',
        position: 'relative',
        opacity: isLocked ? 0.7 : 1,
        border: isLocked ? 
          `1px solid ${theme.palette.divider}` : 
          `2px dashed ${theme.palette.primary.main}`,
        bgcolor: 'transparent',
        '&:hover': !isLocked && {
          bgcolor: alpha(theme.palette.primary.main, 0.05),
          transform: 'translateY(-2px)',
          borderColor: theme.palette.primary.main,
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        }
      }}
      onClick={!isLocked ? onClick : undefined}
    >
      <CardContent sx={{ 
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: 3
      }}>
        <Stack
          spacing={2}
          alignItems="center"
          justifyContent="center"
          sx={{ height: '100%' }}
        >
          {isLocked ? (
            <Box sx={{ 
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1
            }}>
              <LockIcon sx={{ fontSize: 40, color: 'text.secondary' }} />
              <Typography
                variant="caption"
                sx={{
                  color: 'primary.main',
                  bgcolor: alpha(theme.palette.primary.main, 0.1),
                  px: 1,
                  py: 0.5,
                  borderRadius: 1,
                  fontWeight: 'medium'
                }}
              >
                {comingSoonMessage}
              </Typography>
            </Box>
          ) : (
            <AddIcon sx={{ fontSize: 40, color: 'primary.main' }} />
          )}
          <Box sx={{ textAlign: 'center' }}>
            <Typography 
              variant="h6" 
              gutterBottom
              color={isLocked ? 'text.primary' : 'primary.main'}
            >
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {subtitle}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
}

CreateNewAssetCard.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  isLocked: PropTypes.bool,
  comingSoonMessage: PropTypes.string
}; 