import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';

import { SitemarkIcon } from './CustomIcons';
import AppTheme from '../../shared-theme/AppTheme';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { Link as RouterLink } from 'react-router-dom';
import ResetPassword from './ResetPassword';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Checkbox from '@mui/material/Checkbox';
// import { GoogleIcon, SitemarkIcon } from './CustomIcons';
// import { SitemarkIcon } from './CustomIcons'
//import Divider from '@mui/material/Divider';
// import ForgotPassword from './ForgotPassword';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1000,
  backgroundColor: theme.palette.background.paper,
  boxShadow:
    '0px 5px 15px rgba(0, 0, 0, 0.1), 0px 15px 35px rgba(0, 0, 0, 0.05)',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  ...theme.applyStyles('dark', {
    backgroundColor: theme.palette.background.default,
    boxShadow:
      '0px 5px 15px rgba(0, 0, 0, 0.5), 0px 15px 35px rgba(0, 0, 0, 0.08)',
  }),
}));

export default function SignIn({ onClose, redirectPath }) {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [formErrors, setFormErrors] = React.useState({});
  // const [open, setOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [submitError, setSubmitError] = React.useState('');
  const [identifier, setIdentifier] = React.useState('');
  const [resetPasswordOpen, setResetPasswordOpen] = React.useState(false);

  // Comment out these handlers
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const handleResetPasswordOpen = () => {
    setResetPasswordOpen(true);
  };

  const handleResetPasswordClose = () => {
    setResetPasswordOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!validateForm()) return;

    setIsSubmitting(true);
    setSubmitError('');

    const formData = new FormData(event.currentTarget);
    const credentials = {
      identifier,
      password: formData.get('password'),
      remember: formData.get('remember') === 'remember'
    };

    try {
      const response = await login(credentials);
      if (response.success) {
        onClose();
        //navigate(redirectPath || '/home');
        navigate(redirectPath || '/simulator');
      } else {
        setSubmitError(response.message);
      }
    } catch (error) {
      console.error('Login error:', error);
      setSubmitError('An unexpected error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!identifier) {
      errors.identifier = 'Username is required';
      isValid = false;
    }

    if (!document.getElementById('password').value) {
      errors.password = 'Password is required';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  return (
    <AppTheme>
      <CssBaseline enableColorScheme />
      <Stack direction="column" justifyContent="center">
        <Card variant="outlined">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 2,
            }}
          >
            <SitemarkIcon />
          </Box>
          <Typography
            component="h1"
            variant="h4"
            sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
          >
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 2,
            }}
          >
            <FormControl>
              <FormLabel htmlFor="identifier">Username</FormLabel>
              <TextField
                error={!!formErrors.identifier}
                helperText={formErrors.identifier}
                id="identifier"
                type="text"
                name="identifier"
                placeholder="username"
                autoComplete="username"
                autoFocus
                required
                fullWidth
                variant="outlined"
                color={formErrors.identifier ? 'error' : 'primary'}
                sx={{ ariaLabel: 'identifier' }}
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormLabel htmlFor="password">Password</FormLabel>
                <Link
                  component="button"
                  type="button"
                  onClick={handleResetPasswordOpen}
                  variant="body2"
                  sx={{ alignSelf: 'baseline' }}
                >
                  Forgot password?
                </Link>
              </Box>
              <TextField
                error={!!formErrors.password}
                helperText={formErrors.password}
                name="password"
                placeholder="••••••"
                type="password"
                id="password"
                autoComplete="current-password"
                required
                fullWidth
                variant="outlined"
                color={formErrors.password ? 'error' : 'primary'}
              />
            </FormControl>
            {/* Commented out but preserved
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            */}
            {/* Commented out but preserved
            <ForgotPassword open={open} handleClose={handleClose} />
            */}
            {submitError && (
              <Typography 
                color="error" 
                variant="body2" 
                sx={{ textAlign: 'center' }}
              >
                {submitError}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Signing in...' : 'Sign in'}
            </Button>
            <Typography sx={{ textAlign: 'center' }}>
              Don&apos;t have an account?{' '}
              <span>
                <Link
                  component={RouterLink}
                  to="/signup"
                  variant="body2"
                  sx={{ alignSelf: 'center' }}
                >
                  Sign up
                </Link>
              </span>
            </Typography>
          </Box>
          {/* <Divider>or</Divider> */}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {/* <Button
              fullWidth
              variant="outlined"
              onClick={() => alert('Sign in with Google')}
              startIcon={<GoogleIcon />}
            >
              Sign in with Google
            </Button>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => alert('Sign in with Facebook')}
              startIcon={<FacebookIcon />}
            >
              Sign in with Facebook
            </Button> */}
          </Box>
        </Card>
      </Stack>
      <ResetPassword 
        open={resetPasswordOpen}
        handleClose={handleResetPasswordClose}
      />
    </AppTheme>
  );
}

SignIn.propTypes = {
  onClose: PropTypes.func.isRequired,
  redirectPath: PropTypes.string
};
