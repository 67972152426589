import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { authService } from '../../../services/api';

export default function ResetPassword({ open, handleClose }) {
  const [step, setStep] = React.useState(1); // 1: email, 2: token+password
  const [email, setEmail] = React.useState('');
  const [token, setToken] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [status, setStatus] = React.useState({ message: '', severity: 'info' });
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleRequestReset = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      const response = await authService.forgotPassword(email);
      setStatus({
        message: response.message,
        severity: response.success ? 'success' : 'error'
      });
      if (response.success) {
        setStep(2);
      }
    } catch (error) {
      setStatus({
        message: 'Failed to process request. Please try again.',
        severity: 'error'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await authService.resetPassword(token, newPassword);
      setStatus({
        message: response.message,
        severity: response.success ? 'success' : 'error'
      });
      if (response.success) {
        setTimeout(() => {
          handleClose();
        }, 2000);
      }
    } catch (error) {
      setStatus({
        message: 'Failed to reset password. Please try again.',
        severity: 'error'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDialogClose = () => {
    setStep(1);
    setEmail('');
    setToken('');
    setNewPassword('');
    setStatus({ message: '', severity: 'info' });
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperProps={{
        component: 'form',
        onSubmit: step === 1 ? handleRequestReset : handleResetPassword,
      }}
    >
      <DialogTitle>Reset Password</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          {status.message && (
            <Alert severity={status.severity}>{status.message}</Alert>
          )}

          {step === 1 ? (
            <>
              <DialogContentText>
                Enter your email address and we&apos;ll send you instructions to reset your password.
              </DialogContentText>
              <TextField
                autoFocus
                required
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </>
          ) : (
            <>
              <DialogContentText>
                Enter the reset code from your email and your new password.
              </DialogContentText>
              <TextField
                required
                margin="dense"
                id="token"
                label="Reset Code"
                fullWidth
                value={token}
                onChange={(e) => setToken(e.target.value)}
              />
              <TextField
                required
                margin="dense"
                id="new-password"
                label="New Password"
                type="password"
                fullWidth
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>Cancel</Button>
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Processing...' : (step === 1 ? 'Send Reset Code' : 'Reset Password')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ResetPassword.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
}; 