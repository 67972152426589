import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import SettingsIcon from '@mui/icons-material/Settings';
import Stack from '@mui/material/Stack';

// Asset types and positions
const ASSET_TYPES = {
  CONTROLLER: 'controller',
  PV: 'pv',
  BATTERY: 'battery',
  TARIFF: 'tariff',
  LOAD: 'load'
};

const ASSET_POSITIONS = {
  [ASSET_TYPES.CONTROLLER]: { x: 0, y: 0 }, // Center
  [ASSET_TYPES.PV]: { x: 0, y: -150 }, // Above
  [ASSET_TYPES.BATTERY]: { x: 0, y: 150 }, // Below
  [ASSET_TYPES.TARIFF]: { x: -150, y: 0 }, // Left
  [ASSET_TYPES.LOAD]: { x: 150, y: 0 }, // Right
};

const MULTI_ASSET_SPACING = 140;

const shouldConnect = (sourceType, targetType) => {
  return sourceType === ASSET_TYPES.CONTROLLER || targetType === ASSET_TYPES.CONTROLLER;
};

const AssetNode = styled(motion.div)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(0.5),
  width: 120,
  minHeight: 100,
  position: 'relative',
  border: `1px solid ${theme.palette.divider}`,
  textAlign: 'center',
  '& > span': {
    width: '100%',
    textAlign: 'center',
    padding: `0 ${theme.spacing(0.5)}`,
    fontSize: '0.875rem',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    hyphens: 'auto',
    maxWidth: '100%',
  },
  '& .MuiTypography-caption': {
    fontSize: '0.75rem',
    lineHeight: 1.2,
    display: 'block',
    width: '100%',
    padding: `0 ${theme.spacing(0.5)}`,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    hyphens: 'auto',
    maxWidth: '100%',
    whiteSpace: 'normal',
    '&:last-child': {
      marginBottom: theme.spacing(0.5),
      fontSize: '0.7rem',
    }
  },
  ...(theme.palette.mode === 'dark' && {
    border: `2px solid ${theme.palette.grey[700]}`,
    boxShadow: `0 0 10px 0 ${theme.palette.grey[900]}`,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 15px 0 ${theme.palette.grey[800]}`,
    },
  }),
  transition: 'all 0.2s ease-in-out',
}));

const getFlowDirection = (startType, endType) => {
  if ((startType === ASSET_TYPES.PV && endType === ASSET_TYPES.CONTROLLER) ||
      (endType === ASSET_TYPES.PV && startType === ASSET_TYPES.CONTROLLER)) {
    return endType === ASSET_TYPES.PV ? 'reverse' : 'forward';
  }
  
  if (startType === ASSET_TYPES.CONTROLLER && endType === ASSET_TYPES.LOAD) {
    return 'forward';
  }
  
  if ((startType === ASSET_TYPES.BATTERY || startType === ASSET_TYPES.TARIFF) &&
      endType === ASSET_TYPES.CONTROLLER) {
    return 'bidirectional';
  }
  if (startType === ASSET_TYPES.CONTROLLER && 
      (endType === ASSET_TYPES.BATTERY || endType === ASSET_TYPES.TARIFF)) {
    return 'bidirectional';
  }
  return 'forward';
};

const Connection = ({ start, end, startType, endType }) => {
  const flowDirection = getFlowDirection(startType, endType);
  const pathDef = flowDirection === 'reverse'
    ? `M ${end.x},${end.y} Q ${(start.x + end.x) / 2},${(start.y + end.y) / 2} ${start.x},${start.y}`
    : `M ${start.x},${start.y} Q ${(start.x + end.x) / 2},${(start.y + end.y) / 2} ${end.x},${end.y}`;

  return (
    <svg
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
      }}
    >
      <motion.path
        d={pathDef}
        stroke="#2196f3"
        strokeWidth={2}
        strokeOpacity={0.3}
        fill="none"
      />

      {flowDirection === 'bidirectional' ? (
        <>
          <motion.path
            d={pathDef}
            stroke="#2196f3"
            strokeWidth={2}
            fill="none"
            strokeDasharray="5,5"
            initial={{ pathLength: 0, strokeOpacity: 0.8 }}
            animate={{
              pathLength: [0, 1],
              strokeOpacity: [0.8, 0],
            }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              repeatDelay: 1.5,
              ease: "linear",
            }}
          />
          <motion.path
            d={`M ${end.x},${end.y} Q ${(start.x + end.x) / 2},${(start.y + end.y) / 2} ${start.x},${start.y}`}
            stroke="#2196f3"
            strokeWidth={2}
            fill="none"
            strokeDasharray="5,5"
            initial={{ pathLength: 0, strokeOpacity: 0.8 }}
            animate={{
              pathLength: [0, 1],
              strokeOpacity: [0.8, 0],
            }}
            transition={{
              duration: 1.5,
              delay: 1.5,
              repeat: Infinity,
              repeatDelay: 1.5,
              ease: "linear",
            }}
          />
        </>
      ) : (
        <motion.path
          d={pathDef}
          stroke="#2196f3"
          strokeWidth={2}
          fill="none"
          strokeDasharray="5,5"
          initial={{ pathLength: 0, strokeOpacity: 0.8 }}
          animate={{
            pathLength: [0, 1],
            strokeOpacity: [0.8, 0],
          }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            ease: "linear",
          }}
        />
      )}
    </svg>
  );
};

Connection.propTypes = {
  start: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  end: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  startType: PropTypes.string.isRequired,
  endType: PropTypes.string.isRequired,
};

const WidgetNewScenarioTopology = ({ assets }) => {
  console.log('Received assets in topology:', assets);
  const boxRef = React.useRef(null);
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });

  React.useEffect(() => {
    if (boxRef.current) {
      const updateDimensions = () => {
        setDimensions({
          width: boxRef.current.offsetWidth,
          height: boxRef.current.offsetHeight
        });
      };

      updateDimensions();
      window.addEventListener('resize', updateDimensions);

      return () => window.removeEventListener('resize', updateDimensions);
    }
  }, []);

  const calculateNodePositions = (assets) => {
    if (!dimensions.width || !dimensions.height || assets.length === 0) return [];
    
    const centerX = dimensions.width / 2;
    const centerY = dimensions.height / 2;

    const assetsByType = assets.reduce((acc, asset) => {
      if (ASSET_POSITIONS[asset.type]) {
        acc[asset.type] = acc[asset.type] || [];
        acc[asset.type].push(asset);
      }
      return acc;
    }, {});

    const defaultController = {
      id: 'default-controller',
      type: ASSET_TYPES.CONTROLLER,
      name: 'Controller',
      icon: <SettingsIcon color="primary" />,
    };

    const positionedAssets = [];

    if (assets.length > 0) {
      const controller = assetsByType[ASSET_TYPES.CONTROLLER]?.[0] || defaultController;
      positionedAssets.push({
        ...controller,
        x: centerX + ASSET_POSITIONS[ASSET_TYPES.CONTROLLER].x,
        y: centerY + ASSET_POSITIONS[ASSET_TYPES.CONTROLLER].y,
      });
    }

    Object.entries(assetsByType).forEach(([type, typeAssets]) => {
      if (type !== ASSET_TYPES.CONTROLLER && ASSET_POSITIONS[type]) {
        const basePosition = ASSET_POSITIONS[type];
        const totalWidth = (typeAssets.length - 1) * MULTI_ASSET_SPACING;
        const startX = -totalWidth / 2;

        typeAssets.forEach((asset, index) => {
          const offset = index * MULTI_ASSET_SPACING;
          positionedAssets.push({
            ...asset,
            x: centerX + basePosition.x + startX + offset,
            y: centerY + basePosition.y,
          });
        });
      }
    });

    return positionedAssets;
  };

  const nodes = calculateNodePositions(assets);

  return (
    <Box
      ref={boxRef}
      sx={{
        width: '100%',
        height: 600,
        position: 'relative',
        overflow: 'hidden',
        bgcolor: 'background.default',
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'divider',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {dimensions.width > 0 && (
        <>
          {assets.length === 0 ? (
            <Typography 
              color="text.secondary"
              variant="body1"
              sx={{ textAlign: 'center' }}
            >
              Add assets to visualize system topology
            </Typography>
          ) : (
            <>
              {nodes.map((node, i) => 
                nodes.slice(i + 1).map((otherNode) => {
                  if (shouldConnect(node.type, otherNode.type)) {
                    return (
                      <Connection
                        key={`${node.id}-${otherNode.id}`}
                        start={{ x: node.x, y: node.y }}
                        end={{ x: otherNode.x, y: otherNode.y }}
                        startType={node.type}
                        endType={otherNode.type}
                      />
                    );
                  }
                  return null;
                }).filter(Boolean)
              )}

              {nodes.map((node) => (
                <AssetNode
                  key={node.id}
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  style={{
                    position: 'absolute',
                    left: node.x - 60,
                    top: node.y - 60,
                  }}
                >
                  {node.icon}
                  <span>{node.selectedInstance ? node.selectedInstance.name : node.name}</span>
                  {node.selectedInstance && (
                    <Stack spacing={0.5} alignItems="center">
                    </Stack>
                  )}
                </AssetNode>
              ))}
            </>
          )}
        </>
      )}
    </Box>
  );
};

WidgetNewScenarioTopology.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    type: PropTypes.string.isRequired,
    selectedInstance: PropTypes.shape({
      name: PropTypes.string
    })
  })).isRequired,
};

export default WidgetNewScenarioTopology;
