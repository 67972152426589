import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import AppTheme from '../../shared-theme/AppTheme';
import { SitemarkIcon } from '../../marketing-page/sign-in/CustomIcons';
import { useAuth } from '../../../contexts/AuthContext';
import Alert from '@mui/material/Alert';
//import Divider from '@mui/material/Divider';


const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1000,
  backgroundColor: theme.palette.background.paper,
  boxShadow:
    '0px 5px 15px rgba(0, 0, 0, 0.1), 0px 15px 35px rgba(0, 0, 0, 0.05)',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  ...theme.applyStyles('dark', {
    backgroundColor: theme.palette.background.default,
    boxShadow:
      '0px 5px 15px rgba(0, 0, 0, 0.5), 0px 15px 35px rgba(0, 0, 0, 0.08)',
  }),
}));

export default function SignUp() {
  const { signup } = useAuth();
  const [formErrors, setFormErrors] = React.useState({});
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [submitError, setSubmitError] = React.useState('');
  const [submitSuccess, setSubmitSuccess] = React.useState(false);
  const [formData, setFormData] = React.useState({
    name: '',
    surname: '',
    email: '',
    username: ''
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!validateForm()) return;

    setIsSubmitting(true);
    setSubmitError('');
    setSubmitSuccess(false);

    try {
      // Format the data for registration request
      const registrationData = {
        name: formData.name,
        surname: formData.surname,
        email: formData.email,
        username: formData.username
      };

      const response = await signup(registrationData);

      if (response.success) {
        setSubmitSuccess(true);
        // Clear form
        setFormData({
          name: '',
          surname: '',
          email: '',
          username: ''
        });
      } else {
        setSubmitError(response.message || 'Failed to submit registration request.');
      }
    } catch (error) {
      console.error('Registration request error:', error);
      setSubmitError('An unexpected error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!formData.name) {
      errors.name = 'Name is required';
      isValid = false;
    }

    if (!formData.surname) {
      errors.surname = 'Surname is required';
      isValid = false;
    }

    if (!formData.username) {
      errors.username = 'Username is required';
      isValid = false;
    }

    if (!formData.email) {
      errors.email = 'Email is required';
      isValid = false;
    } else if (!formData.email.includes('@') || !formData.email.includes('.')) {
      errors.email = 'Please enter a valid email address';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleChange = (field) => (event) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  return (
    <AppTheme>
      <CssBaseline enableColorScheme />
      <Stack direction="column" justifyContent="center">
        <Card variant="outlined">
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <SitemarkIcon />
          </Box>
          <Typography
            component="h1"
            variant="h4"
            sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
          >
            Sign up
          </Typography>

          {submitSuccess ? (
            <Alert severity="success" sx={{ mt: 2 }}>
              Your registration request has been sent successfully! You&apos;ll receive an email when your account is available.
            </Alert>
          ) : (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 2,
              }}
            >
              <FormControl>
                <FormLabel htmlFor="name">Name</FormLabel>
                <TextField
                  error={!!formErrors.name}
                  helperText={formErrors.name}
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange('name')}
                  required
                  fullWidth
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="surname">Surname</FormLabel>
                <TextField
                  error={!!formErrors.surname}
                  helperText={formErrors.surname}
                  id="surname"
                  name="surname"
                  value={formData.surname}
                  onChange={handleChange('surname')}
                  required
                  fullWidth
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="username">Username</FormLabel>
                <TextField
                  error={!!formErrors.username}
                  helperText={formErrors.username}
                  id="username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange('username')}
                  required
                  fullWidth
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <TextField
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                  id="email"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange('email')}
                  required
                  fullWidth
                />
              </FormControl>

              {submitError && (
                <Typography 
                  color="error" 
                  variant="body2" 
                  sx={{ textAlign: 'center' }}
                >
                  {submitError}
                </Typography>
              )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Sending request...' : 'Sign up'}
              </Button>
            </Box>
          )}

          {/* Commented out social login buttons remain the same */}
        </Card>
      </Stack>
    </AppTheme>
  );
} 