import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

const teamMembers = [
  {
    name: "Dominique Balassi",
    role: "CEO &\nCo-Founder",
    imageDark: "/images/OurTeam/black_bg/dominique-balassi-black.png",
    imageLight: "/images/OurTeam/white_bg/dominique-balassi-white.png",
    linkedin: "https://www.linkedin.com/in/balassi/"
  },
  {
    name: "Luca Agnese",
    role: "AI & Software Developer",
    imageDark: "/images/OurTeam/black_bg/luca-agnese-black.png",
    imageLight: "/images/OurTeam/white_bg/luca-agnese-white.png",
    linkedin: "https://www.linkedin.com/in/lucagne/"
  },
  {
    name: "Vito Mola",
    role: "Business\nDevelopment",
    imageDark: "/images/OurTeam/black_bg/vito-mola-black.png",
    imageLight: "/images/OurTeam/white_bg/vito-mola-white.png",
    linkedin: "https://www.linkedin.com/in/vito-mola/"
  },
  {
    name: "Carlo M. Foglia",
    role: "Control System Developer",
    imageDark: "/images/OurTeam/black_bg/carlo-foglia-black.png",
    imageLight: "/images/OurTeam/white_bg/carlo-foglia-white.png",
    linkedin: "https://www.linkedin.com/in/carlo-maria-foglia/"
  },
  {
    name: "Edwin van Aalten",
    role: "Commercial\nStrategy Advisor",
    imageDark: "/images/OurTeam/black_bg/edwin-vanalten-black.png",
    imageLight: "/images/OurTeam/white_bg/edwin-vanalten-white.png",
    linkedin: "https://www.linkedin.com/in/edwinvanaalten/"
  },
  {
    name: "Robert Kuilman",
    role: "Product\nAdvisor",
    imageDark: "/images/OurTeam/black_bg/robert-kuilman-black.png",
    imageLight: "/images/OurTeam/white_bg/robert-kuilman-white.png",
    linkedin: "https://www.linkedin.com/in/rkuilman/"
  }
];

export default function Team() {
  const theme = useTheme();
  
  // Function to get the correct image based on theme
  const getImage = (member) => {
    return theme.palette.mode === 'dark' ? member.imageDark : member.imageLight;
  };

  return (
    <Box
      id="team"
      sx={(theme) => ({
        width: '100%',
        background: theme.palette.mode === 'dark' ? 
          'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,30,0.3) 100%)' : 
          'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(240,240,255,0.3) 100%)',
        py: { xs: 8, sm: 12 },
      })}
    >
      <Container sx={{ px: 2 }}>
        <Typography
          variant="h2"
          align="left"
          sx={{
            fontSize: { xs: '2rem', md: '2.5rem' },
            mb: 2,
            fontWeight: 'bold',
            color: 'primary.main',
            pl: 2
          }}
        >
          Our Team
        </Typography>
        
        <Grid 
          container 
          spacing={2} 
          justifyContent="center"
          sx={{ mb: 4 }}
        >
          {teamMembers.map((member, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                }}
              >
                <Box sx={{ 
                  width: 220,
                  height: 220,
                  position: 'relative',
                  mb: 2 
                }}>
                  <CardMedia
                    component="img"
                    image={getImage(member)}
                    alt={member.name}
                    sx={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '50%',
                      boxShadow: 3,
                      objectFit: 'cover',
                      objectPosition: 'center 20%',
                      transform: 'scale(0.95)',
                    }}
                  />
                </Box>
                <CardContent sx={{ 
                  p: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  height: '100%'
                }}>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="h6" gutterBottom fontWeight="bold">
                      {member.name}
                    </Typography>
                    <Typography 
                      variant="subtitle1" 
                      color="primary.main" 
                      gutterBottom 
                      fontWeight="medium"
                      sx={{ whiteSpace: 'pre-line' }}
                    >
                      {member.role}
                    </Typography>
                  </Box>
                  <IconButton
                    href={member.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ 
                      color: 'primary.main',
                      mt: 'auto'
                    }}
                  >
                    <LinkedInIcon />
                  </IconButton>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
} 