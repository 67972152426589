// This component is the results view for the simulation, organizing multiple scenarios

import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import simulationService from '../../../../../../services/simulationService';
import AssetResultsCard from './AssetResultsCard';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

// Drag type constant
const SCENARIO_DRAG_TYPE = 'scenario';

// Define PropTypes for scenario shape
const ScenarioPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  // Add other scenario properties as needed
});

// Draggable scenario card component
const DraggableScenarioCard = ({ scenario }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: SCENARIO_DRAG_TYPE,
    item: { scenario },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <Card
      ref={drag}
      variant="outlined"
      sx={(theme) => ({
        cursor: 'move',
        opacity: isDragging ? 0.5 : 1,
        transition: 'all 0.2s',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: theme.shadows[4],
        },
        display: 'inline-block',
        m: 1,
        minWidth: 'fit-content',
      })}
    >
      <CardContent sx={{ 
        textAlign: 'center',
        px: 2,
        py: 1,
        '&:last-child': { pb: 1 },
      }}>
        <Typography variant="h6" noWrap>{scenario.name}</Typography>
      </CardContent>
    </Card>
  );
};

DraggableScenarioCard.propTypes = {
  scenario: ScenarioPropType.isRequired,
};

// Scenario drop zone component
const ScenarioDropZone = ({ onDrop, selectedScenario, onRemove, onSectionHeightChange, sectionHeights }) => {
  const { simulationId } = useParams();
  const [{ isOver }, drop] = useDrop(() => ({
    accept: SCENARIO_DRAG_TYPE,
    drop: (item) => onDrop(item.scenario),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <Box
      ref={drop}
      sx={(theme) => ({
        height: '100%',
        minHeight: 400,
        border: '3px dashed',
        borderColor: isOver ? theme.palette.primary.main : theme.palette.divider,
        borderRadius: 3,
        display: 'flex',
        flexDirection: 'column',
        bgcolor: isOver ? 
          alpha(theme.palette.primary.main, 0.05) : 
          theme.palette.background.default,
        transition: 'all 0.3s',
        p: 2,
        position: 'relative',
      })}
    >
      {selectedScenario && (
        <Stack 
          direction="row" 
          justifyContent="flex-end" 
          sx={{ 
            position: 'absolute',
            top: 8,
            right: 8,
            zIndex: 1,
          }}
        >
          <IconButton
            size="small"
            onClick={() => onRemove()}
            sx={{
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
      )}
      {selectedScenario ? (
        <AssetResultsCard
          simulationId={simulationId}
          scenarioId={selectedScenario.id}
          scenario={selectedScenario}
          onSectionHeightChange={(section, height) => 
            onSectionHeightChange(section, height)
          }
          sectionHeights={sectionHeights}
        />
      ) : (
        <Box sx={{ 
          height: '100%', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center' 
        }}>
          <Typography color="text.secondary">
            Drag and drop a scenario here to visualize results
          </Typography>
        </Box>
      )}
    </Box>
  );
};

ScenarioDropZone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  selectedScenario: ScenarioPropType,
  onSectionHeightChange: PropTypes.func.isRequired,
  sectionHeights: PropTypes.shape({
    static: PropTypes.number,
    assets: PropTypes.number,
    metrics: PropTypes.number,
  }),
};

// Add this new component above WidgetResultsView
const AddScenarioDropZone = ({ onDrop }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: SCENARIO_DRAG_TYPE,
    drop: (item) => onDrop(item.scenario),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <div ref={drop} style={{ height: '100%' }}>
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        sx={(theme) => ({
          height: '100%',
          minHeight: 400,
          borderStyle: 'dashed',
          borderWidth: isOver ? 3 : 2,
          borderRadius: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          backgroundColor: isOver ? 
            alpha(theme.palette.primary.main, 0.05) : 
            theme.palette.background.default,
          borderColor: isOver ? 
            theme.palette.primary.main : 
            theme.palette.divider,
          transition: 'all 0.3s',
        })}
      >
        <Typography variant="button">
          Add new
        </Typography>
        <Typography variant="button">
          scenario
        </Typography>
      </Button>
    </div>
  );
};

AddScenarioDropZone.propTypes = {
  onDrop: PropTypes.func.isRequired,
};

const WidgetResultsView = ({ status }) => {
  const { simulationId } = useParams();
  const [scenarios, setScenarios] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [selectedScenarios, setSelectedScenarios] = React.useState([]);
  const [dropZoneCount, setDropZoneCount] = React.useState(1);
  const [sectionHeights, setSectionHeights] = React.useState({
    static: 0,
    assets: 0,
    metrics: 0,
  });

  React.useEffect(() => {
    const fetchScenarios = async () => {
      try {
        const response = await simulationService.getSimulationScenarios(simulationId);
        if (response.success) {
          setScenarios(response.data.scenarios);
        } else {
          throw new Error(response.error);
        }
      } catch (error) {
        console.error('Failed to fetch scenarios:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    // Only fetch scenarios if simulation is completed
    if (status.toLowerCase() === 'completed') {
      fetchScenarios();
    } else {
      setLoading(false);
    }
  }, [simulationId, status]);

  const handleScenarioDrop = (dropZoneIndex, scenario) => {
    // First remove the old scenario to trigger cleanup
    setSelectedScenarios(prev => {
      const updated = [...prev];
      updated[dropZoneIndex] = null;
      return updated;
    });

    // Then set the new scenario in the next render cycle
    setTimeout(() => {
      setSelectedScenarios(prev => {
        const updated = [...prev];
        updated[dropZoneIndex] = scenario;
        return updated;
      });
    }, 0);
  };

  const handleAddDropZone = () => {
    setDropZoneCount(prev => prev + 1);
    setSelectedScenarios(prev => [...prev, null]);
  };

  const handleRemoveScenario = (dropZoneIndex) => {
    setSelectedScenarios(prev => {
      const updated = [...prev];
      updated[dropZoneIndex] = null;
      return updated;
    });

    // Reset to single drop zone if removing scenario from second zone
    if (dropZoneIndex === 1) {
      setDropZoneCount(1);
      setSelectedScenarios(prev => [prev[0]]); // Keep only the first scenario
    }
  };

  const handleSectionHeightChange = (scenarioIndex, section, height) => {
    setSectionHeights(prev => ({
      ...prev,
      [section]: Math.max(prev[section], height)
    }));
  };

  const sortedScenarios = React.useMemo(() => {
    if (!scenarios) return [];
    return [...scenarios].sort((a, b) => a.name.localeCompare(b.name));
  }, [scenarios]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Stack spacing={2} sx={{ width: '100%', m: 0 }}>
        {/* Draggable Scenario Cards */}
        <Box sx={{ width: '100%' }}>
          <Typography variant="h6" gutterBottom>
            Available Scenarios
          </Typography>
          <Grid 
            container 
            spacing={0} 
            sx={{ 
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            {sortedScenarios.map((scenario) => (
              <Grid 
                item 
                key={scenario.id}
                sx={{
                  flexGrow: 0,
                  flexShrink: 0,
                  p: 1,
                }}
              >
                <DraggableScenarioCard scenario={scenario} />
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Drop Zones and Add Button */}
        <Box sx={{ width: '100%' }}>
          <Grid 
            container 
            spacing={0} 
            sx={{ width: '100%' }}
          >
            {/* First Drop Zone - Always Present */}
            <Grid item xs={12} md={dropZoneCount > 1 ? 6 : 11} sx={{ pr: 1 }}>
              <ScenarioDropZone
                onDrop={(scenario) => handleScenarioDrop(0, scenario)}
                onRemove={() => handleRemoveScenario(0)}
                selectedScenario={selectedScenarios[0]}
                onSectionHeightChange={(section, height) => 
                  handleSectionHeightChange(0, section, height)
                }
                sectionHeights={sectionHeights}
              />
            </Grid>
            
            {dropZoneCount === 1 ? (
              /* Add Drop Zone Button */
              <Grid item xs={12} md={1} sx={{ pl: 1 }}>
                <AddScenarioDropZone
                  onDrop={(scenario) => {
                    handleAddDropZone();
                    handleScenarioDrop(1, scenario);
                  }}
                />
              </Grid>
            ) : (
              /* Second Drop Zone */
              <Grid item xs={12} md={6} sx={{ pl: 1 }}>
                <ScenarioDropZone
                  onDrop={(scenario) => handleScenarioDrop(1, scenario)}
                  onRemove={() => handleRemoveScenario(1)}
                  selectedScenario={selectedScenarios[1]}
                  onSectionHeightChange={(section, height) => 
                    handleSectionHeightChange(1, section, height)
                  }
                  sectionHeights={sectionHeights}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Stack>
    </DndProvider>
  );
};

WidgetResultsView.propTypes = {
  status: PropTypes.string.isRequired,
};

export default WidgetResultsView; 