import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WidgetTimeseries from '../charts/WidgetTimeseries';
import WidgetDailyProfile from '../charts/WidgetDailyProfile';
import WidgetCandlestick from '../charts/WidgetCandlestick';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { UNIFIED_METRICS_CONFIG } from '../../../../../common-components/metrics/MetricsConfig';

const DETAIL_VIEW_OPTIONS = {
  TIMESERIES: 'timeseries',
  DAILY: 'daily',
  MONTHLY: 'monthly'
};

const WidgetUnifiedMetrics = ({ simulationId, scenarioId, scenario, viewType }) => {
  const [selectedMetrics, setSelectedMetrics] = React.useState({});

  // Helper function to create a unique key for each metric-instance combination
  const getMetricKey = (metricKey, modelInstanceId) => `${metricKey}_${modelInstanceId}`;

  // Helper to get model instance by type
  const getModelInstanceByType = (type) => {
    return scenario.model_instances.find(model => {
      switch (type) {
        case 'pv_profile':
          return model.type.startsWith('pv');
        case 'load':
          return model.type === 'load';
        case 'battery':
          return model.type === 'battery';
        case 'controller':
          return model.type.startsWith('controller');
        case 'tariff':
          return model.type.startsWith('tariff');
        default:
          return model.type === type;
      }
    });
  };

  // Update the handleMetricChange function
  const handleMetricChange = (metricKey, assetType) => (event) => {
    const modelInstance = getModelInstanceByType(assetType);
    const uniqueKey = getMetricKey(metricKey, modelInstance.id);
    
    setSelectedMetrics(prev => ({
      ...prev,
      [uniqueKey]: {
        isSelected: event.target.checked,
        metricKey,
        modelInstanceId: modelInstance.id
      }
    }));
  };

  // Helper function to get unit from metric key
  const getMetricUnit = (metricKey) => {
    for (const assetType of Object.keys(UNIFIED_METRICS_CONFIG)) {
      const metrics = UNIFIED_METRICS_CONFIG[assetType].metrics;
      if (metrics[metricKey]) {
        return metrics[metricKey].unit;
      }
    }
    return null;
  };

  // Group selected metrics by unit
  const groupMetricsByUnit = (selectedData) => {
    return selectedData.reduce((acc, metric) => {
      const unit = getMetricUnit(metric.metricKey);
      if (!acc[unit]) {
        acc[unit] = [];
      }
      acc[unit].push(metric);
      return acc;
    }, {});
  };

  // Add helper function to check if metric should be shown
  const shouldShowMetric = (metricKey, assetType, scenario) => {
    const hasAssetType = (type) => scenario.model_instances.some(model => 
      type === 'pv' ? model.type.startsWith('pv') : model.type === type
    );
    
    const hasController = (type) => scenario.model_instances.some(model => 
      model.type.startsWith(type)
    );

    const getControllerParams = (type) => {
      const controller = scenario.model_instances.find(model => 
        model.type.startsWith(type)
      );
      return controller?.params;
    };

    switch (metricKey) {
      case 'pv_curtailed':
        // Show for PVCurtailmentController with PV and tariff
        if (hasController('pv_curtailment_controller') && 
            hasAssetType('pv') && 
            hasAssetType('tariff')) {
          return true;
        }
        // Show for GridController with PV
        if (hasController('grid_controller') && hasAssetType('pv')) {
          return true;
        }
        return false;

      case 'shed_load':
        // Show for GridController with load and finite import limit
        if (hasController('grid_controller') && hasAssetType('load')) {
          const params = getControllerParams('grid_controller');
          return params?.import_limit !== undefined && 
                 params.import_limit !== null && 
                 params.import_limit !== Infinity;
        }
        return false;

      default:
        return true;
    }
  };

  // Modified renderChart function to handle multiple charts
  const renderCharts = () => {
    const selectedData = Object.entries(selectedMetrics)
      .filter(([, data]) => data.isSelected)
      .map(([, data]) => {
        const { metricKey, modelInstanceId } = data;
        const assetType = Object.keys(UNIFIED_METRICS_CONFIG).find(type => 
          Object.keys(UNIFIED_METRICS_CONFIG[type].metrics).includes(metricKey)
        );
        
        const config = UNIFIED_METRICS_CONFIG[assetType];
        
        return {
          modelInstanceId,
          metricKey,
          title: `${config.metrics[metricKey].label} ${config.metrics[metricKey].unit}`,
          unit: config.metrics[metricKey].unit
        };
      });

    if (selectedData.length === 0) {
      return (
        <Typography color="text.secondary">Select metrics to display</Typography>
      );
    }

    const metricsByUnit = groupMetricsByUnit(selectedData);

    const ChartComponent = {
      [DETAIL_VIEW_OPTIONS.TIMESERIES]: WidgetTimeseries,
      [DETAIL_VIEW_OPTIONS.DAILY]: WidgetDailyProfile,
      [DETAIL_VIEW_OPTIONS.MONTHLY]: WidgetCandlestick
    }[viewType];

    if (!ChartComponent) return null;

    return (
      <Stack spacing={3}>
        {Object.entries(metricsByUnit).map(([unit, metrics]) => {
          const [primaryMetric, ...additionalMetrics] = metrics;
          
          return (
            <Box key={unit} sx={{ width: '100%' }}>
              <ChartComponent
                simulationId={simulationId}
                scenarioId={scenarioId}
                modelInstanceId={primaryMetric.modelInstanceId}
                metricKey={primaryMetric.metricKey}
                title={`Metrics ${unit}`}
                additionalMetrics={additionalMetrics.map(metric => ({
                  key: metric.metricKey,
                  title: metric.title,
                  modelInstanceId: metric.modelInstanceId
                }))}
              />
            </Box>
          );
        })}
      </Stack>
    );
  };

  return (
    <Box>
      <Grid container spacing={2}>
        {/* Left side - Metrics Selection */}
        <Grid item xs={12} md={3}>
          <Box sx={{ 
            border: 1, 
            borderColor: 'divider',
            borderRadius: 1,
            height: '100%',
            maxHeight: 600,
            overflow: 'auto'
          }}>
            {Object.entries(UNIFIED_METRICS_CONFIG).map(([assetType, config]) => {
              const modelInstance = getModelInstanceByType(assetType);
              if (!modelInstance) return null;

              return (
                <Accordion key={assetType} disableGutters>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{modelInstance.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormGroup>
                      {Object.entries(config.metrics)
                        // Filter metrics based on conditions
                        .filter(([metricKey]) => shouldShowMetric(metricKey, assetType, scenario))
                        .map(([metricKey, metricConfig]) => {
                          const uniqueKey = getMetricKey(metricKey, modelInstance.id);
                          return (
                            <FormControlLabel
                              key={uniqueKey}
                              control={
                                <Checkbox
                                  checked={selectedMetrics[uniqueKey]?.isSelected || false}
                                  onChange={handleMetricChange(metricKey, assetType)}
                                  size="small"
                                />
                              }
                              label={
                                <Typography variant="body2">
                                  {`${metricConfig.label} ${metricConfig.unit}`}
                                </Typography>
                              }
                            />
                          );
                        })}
                    </FormGroup>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        </Grid>

        {/* Right side - Charts */}
        <Grid item xs={12} md={9}>
          <Box sx={{ 
            border: 1, 
            borderColor: 'divider',
            borderRadius: 1,
            p: 2,
            height: '100%',
            minHeight: 600,
            overflow: 'auto'
          }}>
            {renderCharts()}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

WidgetUnifiedMetrics.propTypes = {
  simulationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  scenarioId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  scenario: PropTypes.shape({
    model_instances: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      params: PropTypes.object
    })).isRequired,
  }).isRequired,
  viewType: PropTypes.oneOf([
    DETAIL_VIEW_OPTIONS.TIMESERIES,
    DETAIL_VIEW_OPTIONS.DAILY,
    DETAIL_VIEW_OPTIONS.MONTHLY
  ]).isRequired
};

export default WidgetUnifiedMetrics; 