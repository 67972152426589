// This component is the main grid that contains the simulation header and results view.

import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import WidgetSimulationHeader from '../components/header/WidgetSimulationHeader';
import WidgetResultsView from '../components/results/WidgetResultsView';
import WidgetSimulationInlineComments from '../components/comments/WidgetSimulationInlineComments';
import simulationService from '../../../../../services/simulationService';

export default function ExistingSimulationGrid({ simulationData }) {
  const handleSaveComments = async (newComments) => {
    try {
      const response = await simulationService.updateSimulation(simulationData.id, {
        comment: newComments
      });
      
      if (!response.success) {
        console.error('Failed to save comments:', response.error);
        // Optionally add error handling/notification here
      }
    } catch (error) {
      console.error('Error saving comments:', error);
      // Optionally add error handling/notification here
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <WidgetSimulationHeader 
            simulationName={simulationData.simulationName}
            status={simulationData.status}
          />
          <WidgetSimulationInlineComments 
            comments={simulationData.comments || ''}
            onSave={handleSaveComments}
          />
        </Grid>

        <Grid item xs={12}>
          <WidgetResultsView 
            status={simulationData.status}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

ExistingSimulationGrid.propTypes = {
  simulationData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    simulationName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    startedAt: PropTypes.string,
    completedAt: PropTypes.string,
    errorMessage: PropTypes.string,
    comments: PropTypes.string
  }).isRequired
}; 