// This widget allows users to add and edit comments for simulation scenarios

import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import CommentIcon from '@mui/icons-material/Comment';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import Fab from '@mui/material/Fab';
import { useRef } from 'react';
import { useTheme } from '@mui/material/styles';

const WidgetSimulationComments = ({ 
  comments = '', 
  onSave = () => {},
  title = 'Scenario Comments',
  section = 'simulation'
}) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [editedComments, setEditedComments] = React.useState(comments);
  const [displayedComments, setDisplayedComments] = React.useState(comments);
  const sectionRef = useRef(null);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    onSave(editedComments);
    setDisplayedComments(editedComments);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedComments(displayedComments);
    setIsEditing(false);
  };

  const getPosition = () => {
    switch (section) {
      case 'simulation':
        return {
          position: 'fixed',
          bottom: 16,
          right: 16,
          zIndex: theme.zIndex.drawer + 2,
        };
      case 'results':
        return {
          position: 'absolute',
          bottom: 16,
          right: 0,
          zIndex: theme.zIndex.drawer + 1,
        };
      default:
        return {
          position: 'absolute',
          top: 0,
          right: 0,
        };
    }
  };

  const getCardPosition = () => {
    switch (section) {
      case 'simulation':
        return {
          position: 'fixed',
          top: 'auto',
          bottom: 80,
          right: 16,
          zIndex: theme.zIndex.drawer + 1,
        };
      case 'results':
        return {
          position: 'absolute',
          top: 40,
          right: 0,
          width: '100%',
          maxWidth: '400px',
          zIndex: theme.zIndex.drawer + 1,
        };
      default:
        return {
          position: 'absolute',
          top: 40,
          right: -56,
        };
    }
  };

  return (
    <Box 
      ref={sectionRef}
      sx={{ 
        position: 'relative',
        ...((section === 'scenario' || section === 'results') && {
          minHeight: 40,
          mr: 7,
          mb: section === 'results' ? 10 : 0
        })
      }}
    >
      <Fab
        color="primary"
        size="small"
        onClick={handleToggle}
        sx={{
          ...getPosition(),
          zIndex: theme.zIndex.drawer + 1,
        }}
      >
        <CommentIcon 
          sx={{ 
            transform: isExpanded ? 'rotate(180deg)' : 'none',
            transition: 'transform 0.2s'
          }}
        />
      </Fab>

      <Collapse in={isExpanded}>
        <Card 
          variant="outlined"
          sx={{
            ...getCardPosition(),
            width: 400,
            maxWidth: 'calc(100vw - 32px)',
            zIndex: theme.zIndex.drawer,
            maxHeight: '70vh',
            overflow: 'auto',
            boxShadow: theme.shadows[8],
            bgcolor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.paper,
            '& .MuiCardContent-root': {
              bgcolor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.paper,
            },
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="subtitle1">
                {title}
              </Typography>
              {!isEditing && (
                <IconButton onClick={handleEdit} size="small" color="primary">
                  <EditIcon />
                </IconButton>
              )}
            </Box>

            <Box sx={{ mt: 2 }}>
              {isEditing ? (
                <>
                  <TextField
                    fullWidth
                    multiline
                    minRows={3}
                    maxRows={8}
                    value={editedComments}
                    onChange={(e) => setEditedComments(e.target.value)}
                    placeholder="Add your comments here..."
                    variant="outlined"
                    sx={{ 
                      mb: 2,
                      '& .MuiInputBase-root': {
                        height: '200px',
                        maxHeight: '200px',
                        overflow: 'auto',
                        display: 'flex',
                        alignItems: 'flex-start',
                        bgcolor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.paper,
                      },
                      '& .MuiInputBase-input': {
                        height: '100% !important',
                        overflow: 'auto !important',
                        maxHeight: 'none !important',
                        padding: '16px !important',
                        bgcolor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.paper,
                      }
                    }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                    <Button
                      size="small"
                      startIcon={<CloseIcon />}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<SaveIcon />}
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </Box>
                </>
              ) : (
                <Box>
                  {displayedComments ? (
                    <Typography
                      variant="body2"
                      sx={{ 
                        whiteSpace: 'pre-wrap',
                        color: 'text.secondary'
                      }}
                    >
                      {displayedComments}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      sx={{ 
                        fontStyle: 'italic',
                        color: 'text.disabled'
                      }}
                    >
                      No comments yet. Click edit to add comments.
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>
      </Collapse>
    </Box>
  );
};

WidgetSimulationComments.propTypes = {
  comments: PropTypes.string,
  onSave: PropTypes.func,
  title: PropTypes.string,
  section: PropTypes.oneOf(['simulation', 'scenario', 'results'])
};

export default WidgetSimulationComments; 