import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AssetLibrary from '../asset-library/AssetLibrary';
import simulationService from '../../../../services/simulationService';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
//import dayjs from 'dayjs';

export default function WidgetSimulationTable() {
  const navigate = useNavigate();
  const [isAssetLibraryOpen, setIsAssetLibraryOpen] = React.useState(false);
  const [simulations, setSimulations] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [simulationToDelete, setSimulationToDelete] = React.useState(null);
  const [editingId, setEditingId] = React.useState(null);
  const [editingName, setEditingName] = React.useState('');

  const handleCreateNew = () => {
    navigate('/simulator/new-simulation');
  };

  const handleEdit = (simulationId, currentName) => {
    setEditingId(simulationId);
    setEditingName(currentName);
  };

  const handleDelete = (simulation) => {
    setSimulationToDelete(simulation);
    setDeleteDialogOpen(true);
  };

  // const formatDateTime = (dateTimeStr) => {
  //   return dayjs(dateTimeStr).format('DD/MM/YYYY HH:mm:ss');
  // };

  const columns = [
    { 
      field: 'simulationName', 
      headerName: 'Simulation Name', 
      flex: 2,
      renderCell: (params) => (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          {editingId === params.row.id ? (
            <Stack direction="row" spacing={1} alignItems="center" sx={{ width: '100%' }}>
              <TextField
                value={editingName}
                onChange={(e) => setEditingName(e.target.value)}
                variant="standard"
                size="small"
                autoFocus
                fullWidth
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (e.key === 'Enter') {
                    handleSaveEdit();
                  }
                  if (e.key === 'Escape') {
                    handleCancelEdit();
                  }
                }}
                onClick={(e) => e.stopPropagation()}
                sx={{ mr: 1 }}
              />
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleSaveEdit();
                }}
                color="primary"
              >
                <CheckIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCancelEdit();
                }}
                color="error"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Stack>
          ) : (
            <Typography
              variant="body2"
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  color: 'primary.main',
                }
              }}
              onClick={() => navigate(`/simulator/${params.row.id}`)}
            >
              {params.value}
            </Typography>
          )}
        </Box>
      )
    },
    /* { 
      field: 'createdAt', 
      headerName: 'Created', 
      flex: 1.5,
      valueFormatter: (params) => formatDateTime(params.value)
    },
    {
      field: 'updatedAt',
      headerName: 'Last Edit',
      flex: 1.5,
      valueFormatter: (params) => formatDateTime(params.value)
    }, */
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        const statusConfig = {
          pending: {
            color: '#FFA726',
            label: '+ PENDING'
          },
          running: {
            color: '#29B6F6',
            label: '+ RUNNING'
          },
          completed: {
            color: '#66BB6A',
            label: '+ COMPLETED'
          },
          failed: {
            color: '#EF5350',
            label: '+ FAILED'
          }
        };

        const status = statusConfig[params.value] || {
          color: '#757575',
          label: `+ ${params.value.toUpperCase()}`
        };

        return (
          <Typography
            variant="caption"
            sx={{
              color: status.color,
              py: 0.75,
              px: 2,
              fontWeight: 600,
              fontSize: '0.75rem',
              whiteSpace: 'nowrap',
              width: 'fit-content'
            }}
          >
            {status.label}
          </Typography>
        );
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Box 
          sx={{ 
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Stack direction="row" spacing={1}>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleEdit(params.row.id, params.row.simulationName);
              }}
              sx={{ color: 'primary.main' }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(params.row);
              }}
              sx={{ color: 'error.main' }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Stack>
        </Box>
      )
    }
  ];

  const handleConfirmDelete = async () => {
    if (!simulationToDelete) return;

    try {
      const result = await simulationService.deleteSimulation(simulationToDelete.id);
      if (result.success) {
        setSimulations(prev => prev.filter(sim => sim.id !== simulationToDelete.id));
        setError(null);
      } else {
        setError(result.error);
      }
    } catch (error) {
      setError('Failed to delete simulation');
      console.error('Delete error:', error);
    }

    setDeleteDialogOpen(false);
    setSimulationToDelete(null);
  };

  const handleSaveEdit = async () => {
    try {
      const result = await simulationService.updateSimulation(editingId, {
        name: editingName
      });

      if (result.success) {
        setSimulations(prev => prev.map(sim => 
          sim.id === editingId 
            ? { ...sim, simulationName: editingName }
            : sim
        ));
        setError(null);
      } else {
        setError(result.error);
      }
    } catch (error) {
      setError('Failed to update simulation name');
      console.error('Update error:', error);
    }
    
    setEditingId(null);
    setEditingName('');
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setEditingName('');
  };

  React.useEffect(() => {
    const fetchSimulations = async () => {
      setLoading(true);
      const result = await simulationService.getSimulations({
        per_page: 50
      });

      if (result.success) {
        const formattedSimulations = result.data.simulations.map(sim => ({
          id: sim.id,
          simulationName: sim.name,
          createdAt: sim.created_at,
          updatedAt: sim.updated_at,
          status: sim.status,
          path: sim.id.toString(),
          onEdit: () => handleEdit(sim.id),
          onDelete: () => handleDelete(sim)
        }));
        setSimulations(formattedSimulations);
        setError(null);
      } else {
        setError(result.error);
      }
      setLoading(false);
    };

    fetchSimulations();
  }, []);

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Stack 
        direction="row" 
        justifyContent="space-between" 
        alignItems="center" 
        sx={{ mb: 2 }}
      >
        <Box>Your Simulations:</Box>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            startIcon={<LibraryBooksIcon />}
            sx={{ 
              borderRadius: 2,
              height: 36.5,
              minWidth: 180
            }}
            onClick={() => setIsAssetLibraryOpen(true)}
          >
            Asset Library
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            sx={{ 
              borderRadius: 2,
              height: 36.5
            }}
            onClick={handleCreateNew}
          >
            Create New Simulation
          </Button>
        </Stack>
      </Stack>
      
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}
      
      <DataGrid
        rows={simulations}
        columns={columns}
        loading={loading}
        autoHeight
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        isRowSelectable={() => !editingId}
        sx={{
          '& .MuiDataGrid-row': {
            cursor: 'default'
          }
        }}
      />

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Delete Simulation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete &ldquo;{simulationToDelete?.simulationName}&rdquo;? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDeleteDialogOpen(false)}
            variant="outlined"
            sx={{
              color: 'text.primary',
              borderColor: 'divider',
              '&:hover': {
                borderColor: 'primary.main',
                backgroundColor: 'action.hover'
              }
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleConfirmDelete} 
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <AssetLibrary 
        open={isAssetLibraryOpen}
        onClose={() => setIsAssetLibraryOpen(false)}
      />
    </Box>
  );
} 