import * as React from 'react';
import { 
  TextField, 
  Stack, 
  InputAdornment, 
  MenuItem,
  IconButton,
  Tooltip,
  Box
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default class ControllersAssetModule {
  static parameters = [
    { 
      name: 'type', 
      label: 'Controller Type', 
      type: 'select', 
      required: true,
      options: [
        { 
          value: 'controller_grid', 
          label: 'Grid Controller',
          description: 'Manages power exchange with the grid by enforcing import/export limits. Useful for peak shaving and grid compliance.'
        },
        { 
          value: 'controller_pvcurtailment', 
          label: 'PV Curtailment Controller',
          description: 'Controls PV output based on price signals. Helps optimize solar production against market conditions and grid constraints.'
        }
      ]
    }
  ];

  static commonParameters = [];

  static additionalParameters = {
    controller_base: [],  // No additional parameters for base controller
    controller_grid: [
      { 
        name: 'import_limit', 
        label: 'Import Limit', 
        unit: 'kW', 
        type: 'number', 
        required: true,
        min: 0
      },
      { 
        name: 'export_limit', 
        label: 'Export Limit', 
        unit: 'kW', 
        type: 'number', 
        required: true,
        min: 0
      }
    ],
    controller_pvcurtailment: [
      { 
        name: 'setpoint', 
        label: 'PV Setpoint', 
        unit: '€/kWh', 
        type: 'number', 
        required: false,
        defaultValue: 100,
        min: 0
      }
    ]
  };

  static validate(data) {
    const errors = {};

    // Remove battery-related validations, keep only controller-specific ones
    if (data.type === 'controller_grid') {
      if (!data.import_limit) {
        errors.import_limit = 'Import limit is required';
      } else if (data.import_limit <= 0) {
        errors.import_limit = 'Import limit must be greater than 0';
      }
      if (!data.export_limit) {
        errors.export_limit = 'Export limit is required';
      } else if (data.export_limit <= 0) {
        errors.export_limit = 'Export limit must be greater than 0';
      }
    }

    // PV curtailment controller validations
    if (data.type === 'controller_pvcurtailment' && data.setpoint !== undefined) {
      if (data.setpoint < 0) {
        errors.setpoint = 'Setpoint must be greater than or equal to 0';
      }
    }

    return errors;
  }

  static transformForAPI(data) {
    const baseData = {
      type: data.type,
    };

    switch (data.type) {
      case 'controller_grid':
        return {
          ...baseData,
          import_limit: parseFloat(data.import_limit),
          export_limit: parseFloat(data.export_limit)
        };
      case 'controller_pvcurtailment':
        return {
          ...baseData,
          setpoint: data.setpoint !== undefined ? parseFloat(data.setpoint) : 100
        };
      default:
        return baseData;
    }
  }

  static renderFields({ data, onChange, errors }) {
    const getNumericValue = (value) => {
      if (!value && value !== 0) {
        return '';
      }
      const numValue = parseFloat(value);
      return isNaN(numValue) ? '' : numValue;
    };

    const selectedOption = this.parameters[0].options.find(opt => opt.value === data.type);
    const typeSpecificParams = this.additionalParameters[data.type] || [];
    const allParams = [...this.commonParameters, ...typeSpecificParams];

    return (
      <Stack spacing={2}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TextField
            select
            label="Controller Type"
            value={data.type || ''}
            onChange={(e) => onChange('type', e.target.value)}
            required
            fullWidth
          >
            {this.parameters[0].options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          
          <Tooltip 
            title={selectedOption?.description || 'Select a controller type to see its description'}
            placement="right"
            arrow
          >
            <IconButton 
              size="small"
              sx={{ 
                color: 'text.secondary',
                '&:hover': {
                  color: 'primary.main'
                }
              }}
            >
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {allParams.map((param) => (
          <TextField
            key={param.name}
            label={param.label}
            type="number"
            value={getNumericValue(data[param.name])}
            onChange={(e) => {
              const newValue = e.target.value;
              onChange(param.name, newValue === '' ? undefined : newValue);
            }}
            error={!!errors?.[param.name]}
            helperText={errors?.[param.name] || 
              (param.defaultValue !== undefined && !data[param.name] ? 
                `Default: ${param.defaultValue}` : undefined)}
            InputProps={{
              endAdornment: param.unit && (
                <InputAdornment position="end">
                  {param.unit}
                </InputAdornment>
              )
            }}
            inputProps={{
              min: param.min,
              max: param.max,
              step: "any"
            }}
            required={param.required}
          />
        ))}
      </Stack>
    );
  }
} 