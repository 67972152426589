// This widget is used to display the timeseries chart for the simulation.

import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chart from 'react-apexcharts';
import simulationService from '../../../../../../../services/simulationService';
import { formatMetricValue, UNIFIED_METRICS_CONFIG } from '../../../../../common-components/metrics/MetricsConfig';

const WidgetTimeseries = ({ simulationId, scenarioId, modelInstanceId, metricKey, title, additionalMetrics = [] }) => {
  const theme = useTheme();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [metricsData, setMetricsData] = React.useState(new Map());

  const getMetricKey = (metric, instanceId) => `${metric}_${instanceId}`;

  const getMetricTypeAndUnit = (metricKey) => {
    // Check in UNIFIED_METRICS_CONFIG
    for (const category of Object.values(UNIFIED_METRICS_CONFIG)) {
      const metric = category.metrics[metricKey];
      if (metric) {
        return {
          type: metric.unit,
          label: metric.label,
          title: `${metric.label} ${metric.unit}`
        };
      }
    }
    
    // If not found, return default
    return {
      type: 'kWh',
      label: title,
      title: title
    };
  };

  const fetchMetricData = async (metric, metricTitle, instanceId) => {
    const cacheKey = getMetricKey(metric, instanceId);
    
    if (metricsData.has(cacheKey)) {
      return {
        name: metricTitle,
        data: metricsData.get(cacheKey)
      };
    }

    try {
      const response = await simulationService.getAssetHourlyProfile(
        simulationId,
        scenarioId,
        instanceId,
        metric
      );

      if (!response?.success || !response?.data?.series) {
        throw new Error(response?.error || 'Invalid data format');
      }

      setMetricsData(prev => new Map(prev).set(cacheKey, response.data.series));

      return {
        name: metricTitle,
        data: response.data.series
      };
    } catch (error) {
      console.error(`Failed to fetch data for metric ${metric}:`, error);
      throw error;
    }
  };

  React.useEffect(() => {
    const fetchNewMetrics = async () => {
      setLoading(true);
      try {
        const allMetrics = [
          { key: metricKey, title, modelInstanceId },
          ...additionalMetrics
        ];

        const metricsToFetch = allMetrics.filter(metric => 
          !metricsData.has(getMetricKey(metric.key, metric.modelInstanceId))
        );

        if (metricsToFetch.length > 0) {
          await Promise.all(
            metricsToFetch.map(metric =>
              fetchMetricData(metric.key, metric.title, metric.modelInstanceId)
            )
          );
        }
      } catch (error) {
        console.error('Failed to fetch timeseries data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNewMetrics();
  }, [simulationId, scenarioId, metricKey, modelInstanceId, title, JSON.stringify(additionalMetrics)]);

  // Memoize series data
  const series = React.useMemo(() => {
    const allMetrics = [
      { key: metricKey, title: getMetricTypeAndUnit(metricKey).title, modelInstanceId },
      ...additionalMetrics
    ];

    return allMetrics
      .map(metric => {
        const cacheKey = getMetricKey(metric.key, metric.modelInstanceId);
        const data = metricsData.get(cacheKey);
        return data ? {
          name: metric.title,
          data: data
        } : null;
      })
      .filter(Boolean);
  }, [metricKey, title, modelInstanceId, additionalMetrics, metricsData]);

  // Memoize chart options
  const chartOptions = React.useMemo(() => {
    const { type, label } = getMetricTypeAndUnit(metricKey);
    
    return {
      chart: {
        type: 'area',
        height: 300,
        toolbar: { show: false },
        background: 'transparent',
        animations: { enabled: false }
      },
      stroke: {
        curve: 'straight',
        width: 2
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.3,
          stops: [0, 90, 100]
        }
      },
      dataLabels: { enabled: false },
      xaxis: {
        type: 'datetime',
        labels: {
          style: { colors: theme.palette.text.secondary },
          datetimeFormatter: {
            year: 'yyyy',
            month: 'MMM yyyy',
            day: 'dd MMM',
            hour: 'HH:mm'
          }
        },
        axisBorder: { show: false },
        axisTicks: { show: false }
      },
      yaxis: {
        labels: {
          style: { colors: theme.palette.text.secondary },
          formatter: (value) => formatMetricValue(value, type)
        },
        title: {
          text: additionalMetrics.length > 0 ? type : `${label} ${type}`,
          style: {
            color: theme.palette.text.secondary
          }
        }
      },
      tooltip: {
        theme: theme.palette.mode,
        x: { 
          format: 'dd MMM yyyy HH:mm'
        },
        y: {
          formatter: (value, { seriesIndex }) => {
            const metric = seriesIndex === 0 ? metricKey : additionalMetrics[seriesIndex - 1].key;
            const { type } = getMetricTypeAndUnit(metric);
            return formatMetricValue(value, type);
          }
        },
        style: {
          fontSize: '12px',
          fontFamily: theme.typography.fontFamily
        }
      },
      grid: {
        borderColor: theme.palette.divider,
        strokeDashArray: 4,
        yaxis: { lines: { show: true } },
        xaxis: { lines: { show: false } }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        labels: { colors: theme.palette.text.primary }
      }
    };
  }, [theme, metricKey, additionalMetrics]);

  // Memoize chart component
  const ChartComponent = React.useMemo(() => (
    <Chart
      options={chartOptions}
      series={series}
      type="area"
      height={300}
    />
  ), [chartOptions, series]);

  if (loading && series.length === 0) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="subtitle1" gutterBottom>{title}</Typography>
          <Box sx={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography color="text.secondary">Loading data...</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  if (error && series.length === 0) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="subtitle1" gutterBottom>{title}</Typography>
          <Box sx={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography color="error">Error: {error}</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="subtitle1" gutterBottom>{title}</Typography>
        <Box sx={{ height: 300, width: '100%' }}>
          {ChartComponent}
        </Box>
      </CardContent>
    </Card>
  );
};

WidgetTimeseries.propTypes = {
  simulationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  scenarioId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  modelInstanceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  metricKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  additionalMetrics: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    modelInstanceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }))
};

export default WidgetTimeseries;
