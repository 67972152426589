import * as React from 'react';
import PropTypes from 'prop-types';
import { TextField, Stack, InputAdornment } from '@mui/material';
import SOCSlider from './sliders/SOCSlider';

// Create a new functional component for the form fields
function BatteryAssetForm({ data, onChange, errors }) {
  const getNumericValue = (value) => {
    if (!value && value !== 0) {
      return '';
    }
    const numValue = parseFloat(value);
    return isNaN(numValue) ? '' : numValue;
  };

  React.useEffect(() => {
    if (data.soc_min === undefined || data.soc_min === null) {
      onChange('soc_min', '0');
    }
    if (data.soc_max === undefined || data.soc_max === null) {
      onChange('soc_max', '100');
    }
  }, []);

  const handleSocChange = (min, max) => {
    const minStr = min !== null && min !== undefined ? min.toString() : '0';
    const maxStr = max !== null && max !== undefined ? max.toString() : '100';
    onChange('soc_min', minStr);
    onChange('soc_max', maxStr);
  };

  return (
    <Stack spacing={2}>
      <SOCSlider
        minSoc={parseFloat(data.soc_min ?? 0)}
        maxSoc={parseFloat(data.soc_max ?? 100)}
        onChange={handleSocChange}
        label="SOC Limits"
      />
      
      {BatteryAssetModule.parameters
        .filter(param => !['soc_min', 'soc_max'].includes(param.name))
        .map((param) => (
          <TextField
            key={param.name}
            label={param.label}
            type="number"
            value={getNumericValue(data[param.name])}
            onChange={(e) => {
              const newValue = e.target.value;
              if (newValue === '' || (!isNaN(newValue) && parseFloat(newValue) >= 0)) {
                onChange(param.name, newValue === '' ? undefined : newValue);
              }
            }}
            error={!!errors?.[param.name]}
            helperText={errors?.[param.name] || (param.defaultValue !== undefined && !data[param.name] ? 
              `Default: ${param.defaultValue}` : undefined)}
            inputProps={{
              min: 0,
              step: "any"
            }}
            InputProps={{
              endAdornment: param.unit && (
                <InputAdornment position="end">
                  {param.unit}
                </InputAdornment>
              )
            }}
            required={param.required}
          />
        ))}
    </Stack>
  );
}

// Add PropTypes validation
BatteryAssetForm.propTypes = {
  data: PropTypes.shape({
    soc_min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    soc_max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    initial_soc: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max_charging_p: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max_discharging_p: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max_energy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    charge_efficiency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    discharge_efficiency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    capital_cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    om_cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.string)
};

// Add default props
BatteryAssetForm.defaultProps = {
  errors: {}
};

export default class BatteryAssetModule {
  static parameters = [
    { name: 'initial_soc', label: 'Initial SOC', unit: '%', type: 'number', required: false, defaultValue: 50 },
    { name: 'max_charging_p', label: 'Max Charging Power', unit: 'kW', type: 'number', required: true },
    { name: 'max_discharging_p', label: 'Max Discharging Power', unit: 'kW', type: 'number', required: true },
    { name: 'max_energy', label: 'Maximum Energy', unit: 'kWh', type: 'number', required: true },
    { name: 'charge_efficiency', label: 'Charge Efficiency', unit: '', type: 'number', required: true },
    { name: 'discharge_efficiency', label: 'Discharge Efficiency', unit: '', type: 'number', required: true },
    { name: 'soc_min', label: 'Minimum SOC', unit: '%', type: 'number', required: true },
    { name: 'soc_max', label: 'Maximum SOC', unit: '%', type: 'number', required: true },
    { name: 'capital_cost', label: 'Capital Cost', unit: '€', type: 'number', required: false, defaultValue: 0 },
    { name: 'om_cost', label: 'O&M Cost', unit: '€/year', type: 'number', required: false, defaultValue: 0 }
  ];

  static validate(data) {
    const errors = {};
    if (data.initial_soc && (data.initial_soc < 0 || data.initial_soc > 100)) {
      errors.initial_soc = 'Initial SOC must be between 0 and 100';
    }
    if (!data.max_charging_p || data.max_charging_p <= 0) {
      errors.max_charging_p = 'Max Charging Power must be greater than 0';
    }
    if (!data.max_discharging_p || data.max_discharging_p <= 0) {
      errors.max_discharging_p = 'Max Discharging Power must be greater than 0';
    }
    if (!data.max_energy || data.max_energy <= 0) {
      errors.max_energy = 'Maximum energy must be greater than 0';
    }
    if (!data.charge_efficiency || data.charge_efficiency <= 0 || data.charge_efficiency > 1) {
      errors.charge_efficiency = 'Charge efficiency must be between 0 and 1';
    }
    if (!data.discharge_efficiency || data.discharge_efficiency <= 0 || data.discharge_efficiency > 1) {
      errors.discharge_efficiency = 'Discharge efficiency must be between 0 and 1';
    }
    const socMin = data.soc_min !== undefined ? parseFloat(data.soc_min) : 0;
    const socMax = data.soc_max !== undefined ? parseFloat(data.soc_max) : 100;
    
    if (isNaN(socMin) || socMin < 0 || socMin > 100) {
      errors.soc_min = 'Minimum SOC must be between 0 and 100';
    }
    if (isNaN(socMax) || socMax < 0 || socMax > 100) {
      errors.soc_max = 'Maximum SOC must be between 0 and 100';
    }
    if (socMin >= socMax) {
      errors.soc_min = 'Minimum SOC must be less than Maximum SOC';
      errors.soc_max = 'Maximum SOC must be greater than Minimum SOC';
    }
    if (data.capital_cost && data.capital_cost < 0) {
      errors.capital_cost = 'Capital cost must be greater than or equal to 0';
    }
    if (data.om_cost && data.om_cost < 0) {
      errors.om_cost = 'O&M cost must be greater than or equal to 0';
    }
    return errors;
  }

  static transformForAPI(data) {
    return {
      type: 'battery',
      initial_soc: data.initial_soc ? parseFloat(data.initial_soc) : 50,
      max_charging_p: parseFloat(data.max_charging_p),
      max_discharging_p: parseFloat(data.max_discharging_p),
      max_energy: parseFloat(data.max_energy),
      charge_efficiency: parseFloat(data.charge_efficiency),
      discharge_efficiency: parseFloat(data.discharge_efficiency),
      soc_min: parseFloat(data.soc_min || 0),
      soc_max: parseFloat(data.soc_max || 100),
      capital_cost: data.capital_cost ? parseFloat(data.capital_cost) : 0,
      om_cost: data.om_cost ? parseFloat(data.om_cost) : 0
    };
  }

  static renderFields(props) {
    return <BatteryAssetForm {...props} />;
  }
} 