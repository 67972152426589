import axios from 'axios';
import { shouldRefreshToken } from '../utils/tokenUtils';

const BACKEND_URL = process.env.REACT_APP_API_URL || 'https://staging.v-ems.com';

// Add new event for auth required
export const AUTH_REQUIRED_EVENT = 'auth:required';

// Add new event for token refresh
export const TOKEN_REFRESH_EVENT = 'auth:token-refreshed';

const api = axios.create({
  baseURL: BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  withCredentials: true
});

// Add request interceptor to add auth token
api.interceptors.request.use(async (config) => {
  if (!config.url.includes('/auth/login') && !config.url.includes('/auth/refresh')) {
    const token = localStorage.getItem('authToken');
    if (token) {
      // Check if token needs refresh
      if (shouldRefreshToken(token)) {
        try {
          const refreshToken = localStorage.getItem('refreshToken');
          if (!refreshToken) {
            throw new Error('No refresh token available');
          }

          const response = await api.get('/auth/refresh', {
            headers: {
              'Authorization': `Bearer ${refreshToken}`
            }
          });

          if (response.data?.access_token) {
            localStorage.setItem('authToken', response.data.access_token);
            // Dispatch token refresh event
            window.dispatchEvent(new CustomEvent(TOKEN_REFRESH_EVENT, {
              detail: { token: response.data.access_token }
            }));
            // Use the new token for the current request
            config.headers.Authorization = `Bearer ${response.data.access_token}`;
          }
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
          // If refresh fails, continue with the current token
          config.headers.Authorization = `Bearer ${token}`;
        }
      } else {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Update response interceptor to handle token refresh and auth required
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
          throw new Error('No refresh token available');
        }

        const response = await api.get('/auth/refresh', {
          headers: {
            'Authorization': `Bearer ${refreshToken}`
          }
        });

        if (response.data?.access_token) {
          localStorage.setItem('authToken', response.data.access_token);
          originalRequest.headers.Authorization = `Bearer ${response.data.access_token}`;
          return api(originalRequest);
        }
      } catch (refreshError) {
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
        // Dispatch auth required event
        window.dispatchEvent(new CustomEvent(AUTH_REQUIRED_EVENT));
        // Return a rejected promise with a specific error type
        return Promise.reject({ 
          isAuthError: true, 
          originalError: error 
        });
      }
    }
    return Promise.reject(error);
  }
);

export const authService = {
  login: async (credentials) => {
    try {
      const response = await api.post('/auth/login', {
        username: credentials.identifier,
        password: credentials.password
      });

      if (response.data?.tokens?.access_token) {
        localStorage.setItem('authToken', response.data.tokens.access_token);
        localStorage.setItem('refreshToken', response.data.tokens.refresh_token);
        
        return {
          success: true,
          token: response.data.tokens.access_token,
          refreshToken: response.data.tokens.refresh_token,
          message: response.data.message
        };
      } else {
        return {
          success: false,
          message: response.data.message || 'Invalid server response: No tokens received'
        };
      }
    } catch (error) {
      console.error('Login error details:', error.response || error);
      return {
        success: false,
        message: error.response?.data?.message || 
                error.message || 
                'Failed to connect to server'
      };
    }
  },

  whoami: async () => {
    const response = await api.get('/auth/whoami');
    return response.data;
  },

  logout: async () => {
    const response = await api.get('/auth/logout');
    return response.data;
  },

  getSessions: async () => {
    try {
      const response = await api.get('/auth/sessions');
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to fetch sessions'
      };
    }
  },

  terminateSession: async (sessionId) => {
    try {
      const response = await api.delete(`/auth/sessions/${sessionId}`);
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to terminate session'
      };
    }
  },

  logoutAll: async () => {
    const response = await api.post('/auth/logout-all');
    return response.data;
  },

  refreshToken: async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    const response = await api.get('/auth/refresh', {
      headers: {
        'Authorization': `Bearer ${refreshToken}`
      }
    });
    return response.data;
  },

  forgotPassword: async (email) => {
    try {
      const response = await api.post('/auth/forgot-password', { email });
      return {
        success: true,
        message: response.data.message
      };
    } catch (error) {
      return {
        success: false,
        message: error.response?.data?.message || 'Failed to process request'
      };
    }
  },

  resetPassword: async (token, newPassword) => {
    try {
      const response = await api.post('/auth/reset-password', {
        token,
        new_password: newPassword
      });
      return {
        success: true,
        message: response.data.message
      };
    } catch (error) {
      return {
        success: false,
        message: error.response?.data?.message || 'Failed to reset password'
      };
    }
  }
};

export const contactService = {
  sendMessage: async (formData) => {
    const response = await api.post('/contact', formData);
    return response.data;
  },
};

export const getModelInstances = async (assetClass) => {
  try {
    const response = await api.get(`/model-instances${assetClass ? `?class=${assetClass}` : ''}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch model instances:', error);
    throw error;
  }
};

export const userService = {
  requestRegistration: async (userData) => {
    try {
      const response = await api.post('/request_registration', userData);
      return {
        success: true,
        message: response.data.message
      };
    } catch (error) {
      return {
        success: false,
        message: error.response?.data?.message || 'Failed to submit registration request'
      };
    }
  }
};

export default api; 