import * as React from 'react';
import { 
  Stack, 
  ToggleButton, 
  ToggleButtonGroup,
  Typography,
  Box,
  TextField,
  InputAdornment
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import TimelineIcon from '@mui/icons-material/Timeline';
import ShapeSliderMethod from './sliders/LoadShapeSlider';
import UploadProfileMethod from './UploadProfileMethod';
//import simulationService from '../../../../../services/simulationService';

export default class LoadsAssetModule {
  static parameters = [
    { 
      name: 'factor', 
      label: 'Scale Factor', 
      unit: '', 
      type: 'number', 
      required: false,
      defaultValue: 1.0
    },
    { 
      name: 'creation_method', 
      label: 'Creation Method *', 
      type: 'select', 
      required: true,
      defaultValue: 'shape',
      options: [
        { value: 'shape', label: 'Shape Consumption' },
        { value: 'file', label: 'Upload Profile' }
      ]
    },
    { 
      name: 'total_consumption',
      label: 'Total Consumption *',
      unit: 'kWh',
      type: 'number',
      required: true,
      showWhen: data => data.creation_method === 'shape'
    },
    { 
      name: 'profile', 
      label: 'Load Profile *', 
      type: 'file', 
      required: true,
      showWhen: data => data.creation_method === 'file'
    },
    { 
      name: 'daily_profile',
      type: 'array',
      required: true,
      showWhen: data => data.creation_method === 'shape'
    },
    { 
      name: 'weekly_profile',
      type: 'array',
      required: true,
      showWhen: data => data.creation_method === 'shape'
    },
    { 
      name: 'yearly_profile',
      type: 'array',
      required: true,
      showWhen: data => data.creation_method === 'shape'
    }
  ];

  static validate(data) {
    const errors = {};
    
    if (data.creation_method === 'file') {
      if (data.factor !== undefined && data.factor <= 0) {
        errors.factor = 'Scale factor must be greater than 0';
      }
      if (!data.profile) {
        errors.profile = 'Profile file is required';
      }
    }

    if (data.creation_method === 'shape') {
      if (!data.total_consumption || data.total_consumption <= 0) {
        errors.total_consumption = 'Total consumption must be greater than 0';
      }
      if (!data.daily_profile?.length === 12) {
        errors.daily_profile = 'Invalid daily profile data';
      }
      if (!data.weekly_profile?.length === 7) {
        errors.weekly_profile = 'Invalid weekly profile data';
      }
      if (!data.yearly_profile?.length === 12) {
        errors.yearly_profile = 'Invalid yearly profile data';
      }
    }

    return errors;
  }

  static transformForAPI(data) {
    if (data.creation_method === 'shape') {
      // For shape method, just return basic load model data
      return {
        type: 'load',
        output_type: 'power',
        // No additional parameters needed for initial creation
      };
    }

    // Keep the original file method unchanged
    return {
      type: 'load',
      output_type: 'power',
      factor: data.factor !== undefined ? parseFloat(data.factor) : 1.0
    };
  }

  static renderFields({ data, onChange, errors }) {
    const handleCreationMethodChange = (event, newMethod) => {
      if (newMethod !== null) {
        onChange('creation_method', newMethod);
        if (newMethod === 'shape') {
          onChange('profile', undefined);
          onChange('factor', undefined);
        } else {
          onChange('total_consumption', undefined);
          onChange('factor', undefined);
        }
      }
    };

    const getNumericValue = (value) => {
      if (!value && value !== 0) {
        return '';
      }
      const numValue = parseFloat(value);
      return isNaN(numValue) ? '' : numValue;
    };

    if (!data.creation_method) {
      onChange('creation_method', 'shape');
    }

    return (
      <Stack spacing={3}>
        <Box>
          <Typography variant="subtitle2" gutterBottom>
            Choose Creation Method *
          </Typography>
          <ToggleButtonGroup
            value={data.creation_method || 'shape'}
            exclusive
            onChange={handleCreationMethodChange}
            fullWidth
          >
            <ToggleButton value="shape">
              <Stack direction="row" spacing={1} alignItems="center">
                <TimelineIcon />
                <Typography>Shape Consumption</Typography>
              </Stack>
            </ToggleButton>
            <ToggleButton value="file">
              <Stack direction="row" spacing={1} alignItems="center">
                <UploadFileIcon />
                <Typography>Upload Profile</Typography>
              </Stack>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {data.creation_method === 'shape' && (
          <Stack spacing={2}>
            <ShapeSliderMethod 
              data={data}
              onChange={onChange}
              errors={errors}
              required={true}
            />
          </Stack>
        )}

        {(data.creation_method || 'shape') === 'file' && (
          <Stack spacing={2}>
            <UploadProfileMethod 
              data={data}
              onChange={onChange}
              errors={errors}
              templateName="load_profile_template"
              required={true}
            />
            <TextField
              label="Scale Factor"
              type="number"
              value={getNumericValue(data.factor)}
              onChange={(e) => {
                const newValue = e.target.value;
                onChange('factor', newValue === '' ? undefined : newValue);
              }}
              error={!!errors?.factor}
              helperText={errors?.factor || 'Default: 1.0'}
              required={false}
              InputProps={{
                endAdornment: <InputAdornment position="end"></InputAdornment>
              }}
            />
          </Stack>
        )}
      </Stack>
    );
  }
} 