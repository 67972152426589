// This file contains all metrics-related configurations

// Static metrics configuration used for summary views
export const STATIC_METRICS_CONFIG = {
  consumption: {
    title: 'Consumption Metrics',
    color: '#ff9800', // Orange
    metrics: {
      battery_to_load: { label: 'Battery to Load', type: 'energy', color: '#ffd54f' },
      grid_to_load: { label: 'Grid to Load', type: 'energy', color: '#ffb74d' },
      load_shed: { label: 'Load Shed', type: 'energy', color: '#ff7043' },
      total_load_energy: { label: 'Total Load Energy', type: 'energy', color: '#f57c00' }
    }
  },
  production: {
    title: 'Production Metrics',
    color: '#4caf50', // Green
    metrics: {
      pv_curtailed: { label: 'PV Curtailed', type: 'energy', color: '#81c784' },
      pv_to_battery: { label: 'PV to Battery', type: 'energy', color: '#66bb6a' },
      pv_to_grid: { label: 'PV to Grid', type: 'energy', color: '#4caf50' },
      pv_to_load: { label: 'PV to Load', type: 'energy', color: '#43a047' },
      total_pv_energy: { label: 'Total PV Energy', type: 'energy', color: '#388e3c' }
    }
  },
  storage: {
    title: 'Storage Metrics',
    color: '#2196f3', // Blue
    metrics: {
      battery_throughput: { label: 'Battery Throughput', type: 'energy', color: '#64b5f6' },
      battery_to_grid: { label: 'Battery to Grid', type: 'energy', color: '#42a5f5' },
      grid_to_battery: { label: 'Grid to Battery', type: 'energy', color: '#2196f3' },
      total_battery_charge: { label: 'Total Battery Charge', type: 'energy', color: '#1e88e5' },
      total_battery_discharge: { label: 'Total Battery Discharge', type: 'energy', color: '#1976d2' }
    }
  },
  financial: {
    title: 'Financial Metrics',
    color: '#9c27b0', // Purple
    metrics: {
      capex: { label: 'CAPEX', type: 'cost', color: '#ba68c8' },
      opex: { label: 'OPEX', type: 'cost', color: '#ab47bc' },
      lcoe: { label: 'LCOE', type: 'price', color: '#9c27b0' },
      net_energy_cost: { label: 'Net Energy Cost', type: 'cost', color: '#8e24aa' },
      total_export_revenue: { label: 'Export Revenue', type: 'cost', color: '#7b1fa2' },
      total_import_cost: { label: 'Import Cost', type: 'cost', color: '#6a1b9a' },
      payback_time: { label: 'Payback Time', type: 'years', color: '#4a148c' },
      roi: { label: 'ROI', type: 'percentage', color: '#4a148c' }
    }
  }
};


export const UNIFIED_METRICS_CONFIG = {
  load: {
    label: 'Load',
    metrics: {
      load_dem: { label: 'Load Demand', unit: '[kW]' }
    }
  },
  pv_profile: {
    label: 'PV',
    metrics: {
      pv_gen: { label: 'Power Generation', unit: '[kW]' }
    }
  },
  battery: {
    label: 'Battery',
    metrics: {
      soc: { label: 'State of Charge', unit: '[%]' },
      p_in: { label: 'Power In', unit: '[kW]' },
      p_out: { label: 'Power Out', unit: '[kW]' }
    }
  },
  controller: {
    label: 'Controller',
    metrics: {
      p_grid: { label: 'Grid Exchange', unit: '[kW]' },
      pv_curtailed: { label: 'PV Power Curtailed', unit: '[kW]' },
      shed_load: { label: 'Shed Load', unit: '[kW]' }
      //battery_to_grid: { label: 'Battery Power to Grid', unit: '[kW]' },
      //battery_to_load: { label: 'Battery Power to Load', unit: '[kW]' },
      //flow2b: { label: 'Flow To Battery', unit: '[kW]' },
      //grid_to_battery: { label: 'Grid Power to Battery', unit: '[kW]' },
      //grid_to_load: { label: 'Grid Power to Load', unit: '[kW]' },
      //grid_violation: { label: 'Grid Violations', unit: '' },
      //pv_to_battery: { label: 'PV Power to Battery', unit: '[kW]' },
      //pv_to_grid: { label: 'PV Power to Grid', unit: '[kW]' },
      //pv_to_load: { label: 'PV Power to Load', unit: '[kW]' },
    }
  },
  tariff: {
    label: 'Tariff',
    metrics: {
      cost: { label: 'Energy Cost', unit: '[€]' },
      price: { label: 'Energy Price', unit: '[€/kWh]' },
    }
  }
};

// Helper function to format metric values
export const formatMetricValue = (value, type) => {
  if (typeof value !== 'number') return value;
  
  switch (type) {
    case 'power':
      return `${value.toFixed(2)} kW`;
    case 'energy':
      return `${value.toFixed(2)} kWh`;
    case 'price':
      return `${value.toFixed(4)} €/kWh`;
    case 'cost':
      return `${Math.round(value / 1000)} k€`;
    case 'percentage':
      return `${(value * 100).toFixed(1)}%`;
    case 'years':
      return `${value.toFixed(1)} years`;
    default:
      return value.toFixed(2);
  }
}; 