// This widget is used to display the candlestick chart for the simulation.

import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chart from 'react-apexcharts';
import simulationService from '../../../../../../../services/simulationService';
import dayjs from 'dayjs';
import { formatMetricValue, UNIFIED_METRICS_CONFIG } from '../../../../../common-components/metrics/MetricsConfig';

const WidgetCandlestick = ({ simulationId, scenarioId, modelInstanceId, metricKey, title, additionalMetrics = [] }) => {
  const theme = useTheme();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [metricsData, setMetricsData] = React.useState(new Map());

  const getMetricKey = (metric, instanceId) => `${metric}_${instanceId}`;

  const getMetricTypeAndUnit = React.useCallback((metricKey) => {
    // Check in UNIFIED_METRICS_CONFIG
    for (const category of Object.values(UNIFIED_METRICS_CONFIG)) {
      const metric = category.metrics[metricKey];
      if (metric) {
        return {
          type: metric.unit,
          label: metric.label,
          title: `${metric.label} ${metric.unit}`
        };
      }
    }
    
    // If not found, return default
    return {
      type: 'kWh',
      label: title,
      title: title
    };
  }, [title]);

  const fetchMetricData = async (metric, metricTitle, instanceId) => {
    const cacheKey = getMetricKey(metric, instanceId);
    
    if (metricsData.has(cacheKey)) {
      return {
        name: metricTitle,
        data: metricsData.get(cacheKey)
      };
    }

    try {
      const response = await simulationService.getAssetCandlestick(
        simulationId,
        scenarioId,
        instanceId,
        metric
      );

      if (!response?.success || !response?.data?.values) {
        throw new Error(response?.error || 'Invalid data format');
      }

      const boxPlotData = response.data.values.map(item => ({
        x: dayjs(item.timestamp).format('MMM YYYY'),
        y: [item.min, item.q1, item.median, item.q3, item.max]
      }));

      setMetricsData(prev => new Map(prev).set(cacheKey, boxPlotData));

      return {
        name: metricTitle,
        data: boxPlotData
      };
    } catch (error) {
      console.error(`Failed to fetch data for metric ${metric}:`, error);
      throw error;
    }
  };

  React.useEffect(() => {
    const fetchNewMetrics = async () => {
      setLoading(true);
      try {
        const allMetrics = [
          { key: metricKey, title, modelInstanceId },
          ...additionalMetrics
        ];

        const metricsToFetch = allMetrics.filter(metric => 
          !metricsData.has(getMetricKey(metric.key, metric.modelInstanceId))
        );

        if (metricsToFetch.length > 0) {
          await Promise.all(
            metricsToFetch.map(metric =>
              fetchMetricData(metric.key, metric.title, metric.modelInstanceId)
            )
          );
        }
      } catch (error) {
        console.error('Failed to fetch candlestick data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNewMetrics();
  }, [simulationId, scenarioId, metricKey, modelInstanceId, title, JSON.stringify(additionalMetrics)]);

  const series = React.useMemo(() => {
    const allMetrics = [
      { key: metricKey, title: getMetricTypeAndUnit(metricKey).title, modelInstanceId },
      ...additionalMetrics
    ];

    return allMetrics
      .map(metric => {
        const cacheKey = getMetricKey(metric.key, metric.modelInstanceId);
        const data = metricsData.get(cacheKey);
        return data ? {
          name: metric.title,
          type: 'boxPlot',
          data: data
        } : null;
      })
      .filter(Boolean);
  }, [metricKey, title, modelInstanceId, additionalMetrics, metricsData]);

  const chartOptions = React.useMemo(() => {
    const { type, label } = getMetricTypeAndUnit(metricKey);
    
    return {
      chart: {
        type: 'boxPlot',
        height: 300,
        toolbar: { show: false },
        background: 'transparent',
        animations: { enabled: false },
        zoom: { enabled: false }
      },
      plotOptions: {
        boxPlot: {
          colors: {
            upper: theme.palette.primary.light,
            lower: theme.palette.primary.dark
          }
        }
      },
      xaxis: {
        type: 'category',
        labels: {
          style: {
            colors: theme.palette.text.secondary,
            fontSize: '12px'
          },
          rotateAlways: false
        },
        axisBorder: { show: false },
        axisTicks: { show: false }
      },
      yaxis: {
        labels: {
          style: { colors: theme.palette.text.secondary },
          formatter: (value) => formatMetricValue(value, type)
        },
        title: {
          text: additionalMetrics.length > 0 ? type : `${label} ${type}`,
          style: {
            color: theme.palette.text.secondary
          }
        }
      },
      tooltip: {
        theme: theme.palette.mode,
        shared: true,
        custom: function({ seriesIndex, dataPointIndex }) {
          const series = metricsData.get(getMetricKey(
            seriesIndex === 0 ? metricKey : additionalMetrics[seriesIndex - 1].key,
            seriesIndex === 0 ? modelInstanceId : additionalMetrics[seriesIndex - 1].modelInstanceId
          ));
          
          if (!series || !series[dataPointIndex]) return '';
          
          const point = series[dataPointIndex];
          const metric = seriesIndex === 0 ? metricKey : additionalMetrics[seriesIndex - 1].key;
          const { type } = getMetricTypeAndUnit(metric);
          
          return `
            <div style="
              padding: 12px;
              background: ${theme.palette.background.paper};
              border: 1px solid ${theme.palette.divider};
              border-radius: ${theme.shape.borderRadius}px;
              box-shadow: ${theme.shadows[3]};
              font-size: 12px;
              font-family: ${theme.typography.fontFamily};
              color: ${theme.palette.text.primary};
            ">
              <div style="font-weight: 500; margin-bottom: 8px;">
                ${point.x}
              </div>
              <div style="display: flex; flex-direction: column; gap: 4px;">
                <div>Maximum: ${formatMetricValue(point.y[4], type)}</div>
                <div>Q3 (75th): ${formatMetricValue(point.y[3], type)}</div>
                <div>Median: ${formatMetricValue(point.y[2], type)}</div>
                <div>Q1 (25th): ${formatMetricValue(point.y[1], type)}</div>
                <div>Minimum: ${formatMetricValue(point.y[0], type)}</div>
              </div>
            </div>
          `;
        }
      },
      grid: {
        borderColor: theme.palette.divider,
        padding: { right: 20 },
        xaxis: { lines: { show: false } },
        yaxis: { lines: { show: true } }
      },
      theme: {
        mode: theme.palette.mode
      },
      legend: {
        markers: {
          fillColors: Array(additionalMetrics.length + 1).fill(theme.palette.primary.main)
        },
        labels: {
          colors: theme.palette.text.primary
        }
      }
    };
  }, [theme, metricKey, additionalMetrics, metricsData, getMetricTypeAndUnit]);

  if (loading) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="subtitle1" gutterBottom>{title}</Typography>
          <Box sx={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography color="text.secondary">Loading data...</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="subtitle1" gutterBottom>{title}</Typography>
          <Box sx={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography color="error">Error: {error}</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  if (!series.length) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="subtitle1" gutterBottom>{title}</Typography>
          <Box sx={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography color="text.secondary">No data available</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="subtitle1" gutterBottom>{title}</Typography>
        <Box sx={{ height: 300, width: '100%' }}>
          <Chart
            options={chartOptions}
            series={series}
            type="boxPlot"
            height={300}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

WidgetCandlestick.propTypes = {
  simulationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  scenarioId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  modelInstanceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  metricKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  additionalMetrics: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    modelInstanceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }))
};

export default WidgetCandlestick;
