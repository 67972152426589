// A card component that displays all results for a specific scenario, including:
// Static results (energy metrics, tariff metrics from WidgetStaticResults)
// Asset-specific parameters and metrics

import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import WidgetStaticResults from './WidgetStaticResults';
import { 
  ASSET_TYPE_LABELS, 
  getAssetIcon
} from '../../../../common-components/existing-assets/AssetCategories';

// Helper function to get human-readable asset type
const getAssetTypeLabel = (type) => ASSET_TYPE_LABELS[type] || type;

const AssetResultsCard = ({
  simulationId,
  scenarioId,
  scenario
}) => {
  const [selectedAssetId, setSelectedAssetId] = React.useState(null);
  const staticResultsRef = React.useRef(null);

  return (
    <Stack spacing={3}>
      {/* Static Results Section with Topology */}
      <Box ref={staticResultsRef}>
        <WidgetStaticResults
          simulationId={simulationId}
          scenarioId={scenarioId}
          scenarioName={scenario.name}
          assets={scenario.model_instances.map(model => ({
            id: model.id,
            name: model.name,
            type: model.type,
            icon: getAssetIcon(model.type) ? React.createElement(getAssetIcon(model.type)) : null,
            selectedInstance: model.params ? {
              name: model.name,
              type: getAssetTypeLabel(model.type)
            } : undefined
          }))}
          selectedAssetId={selectedAssetId}
          onAssetSelect={setSelectedAssetId}
        />
      </Box>
    </Stack>
  );
};

AssetResultsCard.propTypes = {
  simulationId: PropTypes.string.isRequired,
  scenarioId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  scenario: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    name: PropTypes.string.isRequired,
    model_instances: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ]).isRequired,
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        params: PropTypes.object
      })
    ).isRequired
  }).isRequired
};

export default AssetResultsCard; 